import React from "react";

// Timeout button that displays the spinner while waiting for the request to complete
import { statusFlags } from "helpers/constants";

// and then displays either a passing or failing icon for 2 seconds
const TimeoutButton = ({ initial, final }: TimeoutButtonProps) => {
  const { INITIAL, FINAL } = statusFlags;
  const [status, setStatus] = React.useState(INITIAL);
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setStatus(FINAL);
    }, 2000);
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  });

  return <>{status === INITIAL ? initial : final}</>;
};

export default TimeoutButton;

interface TimeoutButtonProps {
  final: string | React.ReactNode;
  initial: string | React.ReactNode;
}
