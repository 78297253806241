import React from "react";
import { Text, Button, Flex, Network } from "@mediavine/ui";
import LoginModal from "components/modals/LoginModal";
import { SiteDataContext } from "state/context/site/siteCtx";
import { useHistory } from "react-router-dom";

export default function PaymentModal() {
  const { id, needs_payment } = React.useContext(SiteDataContext);
  const {
    userData: { roles },
    // @ts-ignore
  } = React.useContext<UserContextProps>(Network.JWTContext);
  const history = useHistory();
  return (
    <LoginModal
      flag="payment_modal"
      heading="We need your payment information."
      open={needs_payment && !roles.includes("admin")}
    >
      <Text>
        You must complete your Payment Profile with Tipalti in order to receive
        payments from Mediavine. You can change this information at any time in
        the Payments tab in your dashboard.
      </Text>
      <Flex justify="center">
        <Button
          kind="primary"
          onClick={() =>
            history.push(`${process.env.PUBLIC_URL}/sites/${id}/payments`)
          }
        >
          Go to payment page
        </Button>
      </Flex>
    </LoginModal>
  );
}
