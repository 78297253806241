import { Theme } from "@mui/material/styles";
import { lighten, transparentize } from "polished";

export default function Alert(theme: Theme) {
  return {
    variants: [
      {
        props: { variant: "outlined" },
      },
    ],
    MuiAlert: {
      styleOverrides:
        theme.palette.mode === "dark"
          ? {
              outlinedInfo: {
                backgroundColor: "rgba(0, 0, 0, 0.9)",
                borderColor: theme.palette.primary.main,
                color: transparentize(
                  0.25,
                  lighten(0.3, theme.palette.primary.main)
                ),

                "& .MuiAlert-icon": {
                  color: theme.palette.primary.main,
                },

                "& .MuiAlert-action button": {
                  color: transparentize(
                    0.25,
                    lighten(0.3, theme.palette.primary.main)
                  ),
                },
              },
              outlinedError: {
                borderColor: theme.palette.error.main,
                backgroundColor: "#180605E5",
                color: theme.palette.error.main,

                "& .MuiAlert-icon": {
                  color: theme.palette.error.main,
                },

                "& .MuiAlert-action button": {
                  color: transparentize(
                    0.25,
                    lighten(0.3, theme.palette.error.dark)
                  ),
                },
              },
              outlinedSuccess: {
                color: "#444444",
                backgroundColor: "#fbfffc",
              },
            }
          : {
              outlinedInfo: {
                backgroundColor: theme.palette.primary.light,
                borderColor: theme.palette.primary.main,
                color: theme.palette.primary.dark,

                "& .MuiAlert-icon": {
                  color: theme.palette.primary.main,
                },

                "& .MuiAlert-action button, .MuiButton-text": {
                  color: theme.palette.primary.dark,
                },
              },
              outlinedError: {
                borderColor: theme.palette.error.main,
                backgroundColor: theme.palette.error.light,
                color: theme.palette.error.dark,

                "& .MuiAlert-icon": {
                  color: theme.palette.error.main,
                },

                "& .MuiAlert-action button, .MuiButton-text": {
                  color: theme.palette.error.dark,
                },
              },
            },
    },
  };
}
