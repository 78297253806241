import { SiteData } from "./types";

export const DEFAULT_SITE_DATA: SiteData = {
  id: 0,
  slug: "",
  title: "",
  logo: "",
  screenshot: "",
  avatar: "",
  killswitch: false,
  deactivated: false,
  accepted_terms_of_service: true,
  needs_payment: false,
  loyalty: {
    loyalty_bonus: "",
    live_on: "",
    anniversary_on: "",
    revenue_share: 0.75,
  },
  domain: "",
  givenNotice: false,
  lessLazyMode: false,
  chicory_enabled: false,
  zergnet_enabled: false,
  owned: false,
  offering_id: 1,
  features: [],
  disable_reporting: false,
  loyalty_bonus_disabled: false,
  disable_onboarding_wizard: null,

  // Premiere settings
  premiere_enrolled: false,
  premiere_accepted: false,
  premiere_manage_account: false,
  premiere_accepted_by: undefined,
  premiere_accepted_on: undefined,

  // Pro settings
  pro_accepted: null,
  pro_invited: false,
  revenue_share_pro: 0,

  // Enterprise settings
  enterprise_tier: false,

  // Address fields
  address_exists: undefined,
  address_expired: undefined,

  // Recipe Ad settings
  enable_automatic_recipe_selectors: true,

  ga4_property_connected: false,

  ganalytics_state: "",

  ga4_live_on: null,

  ganalytics_disconnected_at: "",
  analytics_connection_status: null,

  grow_site_id: null,

  uuid: undefined,

  gutter_enable: false,

  // sertify
  sertify_approved: false,

  // Helper functions
  refetchSiteData: () => {
    window.location.reload();
  },
};
