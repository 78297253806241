import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Box, Grid, Flex, Icon, Network } from "@mediavine/ui";
import SignInWithGoogleButton from "components/SignInWithGoogleButton";
import { SiteUser } from "helpers/context";
import LoginModal from "components/modals/LoginModal";
import { FeatureFlagContext } from "components/FeatureFlag";
import useGoogleAnalytics from "helpers/useGoogleAnalytics";
import { SiteDataContext } from "state/context/site/siteCtx";
import { useGA4 } from "hooks";
import RenderIf from "components/RenderIf";
import { Link } from "react-router-dom";

export default function AnalyticsAuthDisconnectedModal() {
  const {
    id,
    features = [],
    ganalytics_state,
  } = React.useContext(SiteDataContext);
  const {
    userData: { roles, site_users },
    // @ts-ignore
  } = React.useContext<UserContextProps>(Network.JWTContext);
  const isUserOwnerOfSite = site_users.some((site_user: SiteUser) => {
    return (
      site_user.site_id === Number(id) && site_user.roles.includes("owner")
    );
  });

  // google analytics hook reference
  const googleAnalytics = useGoogleAnalytics();

  // User feature flags
  const { flags } = React.useContext(FeatureFlagContext);

  const analyticsTokenFlagEnabled =
    flags.includes("analytics_token") || features.includes("analytics_token");
  const { gateKeepGA4, status } = useGA4();
  // general connection/disconnection flag
  // it may be disconnected because it's a legacy connection or because it actually got disconnected
  const [isConnected, setIsConnected] = useState(false);
  // Legacy connections have null or '' or NONE_LEGACY for the ganalytics_state property
  const legacyConnection = !isConnected && ganalytics_state === "NONE_LEGACY";

  // Make sure to update the isConnected flag whenever ganalytics_state changes
  useEffect(() => {
    if (isConnected === false) {
      setIsConnected(
        ganalytics_state === "DISCONNECTED" ||
          ganalytics_state === "NONE_NEW_SITE" ||
          ganalytics_state === "NONE_LEGACY" ||
          ganalytics_state === null ||
          (ganalytics_state === "" ? false : true)
      );
    }
  }, [ganalytics_state, isConnected]);

  return (
    <LoginModal
      id="modal-analytics-auth"
      heading="Confirm Business Information"
      width="350px"
      height="345px"
      open={
        // prettier-ignore
        !roles.includes('admin') &&
        isUserOwnerOfSite &&
        (gateKeepGA4(
          // prettier-ignore
          (isConnected === false) && (ganalytics_state !== 'NONE_NEW_SITE'),
          // prettier-ignore
          (status === 'disconnected')
        ) as boolean) &&
        // prettier-ignore
        (legacyConnection === false) &&
        analyticsTokenFlagEnabled
      }
      useHeader={false}
    >
      <Box>
        <Grid>
          <Flex justify="center" align="center" direction="column">
            <IconWrapper>
              <Icon size="73px" kind="analytics" />
            </IconWrapper>
            <TitleWrapper>Google Analytics Disconnected</TitleWrapper>
            <DescriptionWrapper>
              In order to provide you with accurate Reporting information,
              please reconnect your Google Analytics account.
            </DescriptionWrapper>
            <RenderIf isTrue={status !== "disconnected"}>
              <SignInWithGoogleButton
                onClick={() =>
                  googleAnalytics
                    .grantOfflineAccess()
                    .then(() => setIsConnected(true))
                }
                label="Sign In With Google"
                style={{ marginTop: "20px" }}
              />
            </RenderIf>
            <RenderIf isTrue={status === "disconnected"}>
              <Link
                style={{ marginTop: "12px" }}
                to={`/sites/${id}/settings/google-analytics-4`}
              >
                Go to Settings
              </Link>
            </RenderIf>
          </Flex>
        </Grid>
      </Box>
    </LoginModal>
  );
}

const IconWrapper = styled.div`
  padding: 20px;
`;
const TitleWrapper = styled.div`
  font-weight: bold;
  font-size: 20px;
  text-align: center;
`;
const DescriptionWrapper = styled.div`
  margin-top: 11px;
  text-align: center;
  font-size: 14px;
`;
