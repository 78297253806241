import { View } from "./types";
import packageJson from "../../package.json";

// View toggles
export const PAGE_VIEWS: View = "pageviews";
export const SESSIONS: View = "sessions";

/** Max height used for tables. */
export const TABLE_HEIGHT = "450px";

/** Default limit for paginated reports. Set by API. */
export const REPORTS_PER_PAGE = 25;

export const SNOWPLOW_APP_ID = "dashboard";
export const SNOWPLOW_TRACKER_NAMESPACE = "dashboard-app-prod";
export const REACT_APP_PACKAGE_VERSION = "1.0";

// Statuses
const INITIAL = "initial";
const ACTIVE = "active";
const COMPLETED = "completed";
const FAILED = "error";
const FINAL = "final";

export const statusFlags = { INITIAL, ACTIVE, COMPLETED, FAILED, FINAL };

export const PACKAGE_JSON_VERSION = packageJson.version;
export const IS_DEV_MODE = process.env.REACT_APP_ENV === "development";

/**
 * Clickwrap Dashboard ToS
 */
const DASHBOARD_STAGING_CONTRACT_ID = requiredEnvVar(
  "REACT_APP_DASHBOARD_STAGING_CONTRACT_ID"
);
const DASHBOARD_PRODUCTION_CONTRACT_ID = requiredEnvVar(
  "REACT_APP_DASHBOARD_PRODUCTION_CONTRACT_ID"
);
const DASHBOARD_STAGING_GROUP_KEY = requiredEnvVar(
  "REACT_APP_DASHBOARD_STAGING_GROUP_KEY"
);
const DASHBOARD_PRODUCTION_GROUP_KEY = requiredEnvVar(
  "REACT_APP_DASHBOARD_PRODUCTION_GROUP_KEY"
);
export const DASHBOARD_CONTRACT_ID = Number(
  IS_DEV_MODE ? DASHBOARD_STAGING_CONTRACT_ID : DASHBOARD_PRODUCTION_CONTRACT_ID
);
export const DASHBOARD_GROUP_KEY = IS_DEV_MODE
  ? DASHBOARD_STAGING_GROUP_KEY
  : DASHBOARD_PRODUCTION_GROUP_KEY;
/**
 * Clickwrap Pro ToS
 */
const PRO_STAGING_CONTRACT_ID = requiredEnvVar(
  "REACT_APP_PRO_STAGING_CONTRACT_ID"
);
const PRO_PRODUCTION_CONTRACT_ID = requiredEnvVar(
  "REACT_APP_PRO_PRODUCTION_CONTRACT_ID"
);
const PRO_STAGING_GROUP_KEY = requiredEnvVar("REACT_APP_PRO_STAGING_GROUP_KEY");
const PRO_PRODUCTION_GROUP_KEY = requiredEnvVar(
  "REACT_APP_PRO_PRODUCTION_GROUP_KEY"
);

export const PRO_CONTRACT_ID = Number(
  IS_DEV_MODE ? PRO_STAGING_CONTRACT_ID : PRO_PRODUCTION_CONTRACT_ID
);
export const PRO_GROUP_KEY = IS_DEV_MODE
  ? PRO_STAGING_GROUP_KEY
  : PRO_PRODUCTION_GROUP_KEY;
/**
 * Clickwrap Premiere ToS
 */
const PREMIERE_STAGING_CONTRACT_ID = requiredEnvVar(
  "REACT_APP_PREMIERE_STAGING_CONTRACT_ID"
);
const PREMIERE_PRODUCTION_CONTRACT_ID = requiredEnvVar(
  "REACT_APP_PREMIERE_PRODUCTION_CONTRACT_ID"
);
const PREMIERE_STAGING_GROUP_KEY = requiredEnvVar(
  "REACT_APP_PREMIERE_STAGING_GROUP_KEY"
);
const PREMIERE_PRODUCTION_GROUP_KEY = requiredEnvVar(
  "REACT_APP_PREMIERE_PRODUCTION_GROUP_KEY"
);
export const PREMIERE_CONTRACT_ID = Number(
  IS_DEV_MODE ? PREMIERE_STAGING_CONTRACT_ID : PREMIERE_PRODUCTION_CONTRACT_ID
);
export const PREMIERE_GROUP_KEY = IS_DEV_MODE
  ? PREMIERE_STAGING_GROUP_KEY
  : PREMIERE_PRODUCTION_GROUP_KEY;
/**
 * Clickwrap Access Tokens
 */
const STAGING_ACCESS_ID = requiredEnvVar("REACT_APP_STAGING_ACCESS_ID");
const PRODUCTION_ACCESS_ID = requiredEnvVar("REACT_APP_PRODUCTION_ACCESS_ID");
export const CLICKWRAP_ACCESS_ID = IS_DEV_MODE
  ? STAGING_ACCESS_ID
  : PRODUCTION_ACCESS_ID;
const CLICKWRAP_STAGING_ACCESS_TOKEN = requiredEnvVar(
  "REACT_APP_CLICKWRAP_STAGING_ACCESS_TOKEN"
);
const CLICKWRAP_PRODUCTION_ACCESS_TOKEN = requiredEnvVar(
  "REACT_APP_CLICKWRAP_ACCESS_TOKEN"
);
export const CLICKWRAP_ACCESS_TOKEN = IS_DEV_MODE
  ? CLICKWRAP_STAGING_ACCESS_TOKEN
  : CLICKWRAP_PRODUCTION_ACCESS_TOKEN;

function requiredEnvVar(name: string): string {
  const value = process.env[name];
  if (!value) {
    throw new Error(`Required environment variable "${name}" not found`);
  }
  return value;
}
