import { Theme } from "@mui/material/styles";

export default function TextField(theme: Theme) {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            height: "44px",
          },

          ".MuiSvgIcon-root, .MuiButtonBase-root": {
            color: "#9e9e9e",
          },

          "&:hover": {
            borderColor: "rgba(0, 0, 0, 0.23)",
          },

          "& .MuiInputBase-root.Mui-focused.MuiInputBase-adornedEnd": {
            "& .MuiButtonBase-root, .MuiSvgIcon-root": {
              color: theme.palette.primary.main,
            },
          },
        },
        filled: {
          backgroundColor: "#00000017",
        },
      },
    },
  };
}
