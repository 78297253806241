import React from "react";
import { SvgIcon } from "@mui/material";

export default function Uplift(props: React.ComponentProps<typeof SvgIcon>) {
  return (
    <SvgIcon width="24" height="25" viewBox="0 0 24 25" fill="none" {...props}>
      <path
        d="M12 24.25C18.6274 24.25 24 18.8774 24 12.25C24 5.62258 18.6274 0.25 12 0.25C5.37258 0.25 0 5.62258 0 12.25C0 18.8774 5.37258 24.25 12 24.25Z"
        fill="#DC4F3E"
      />
      <path
        d="M5.65339 18.3781C5.61691 18.3781 5.61691 18.3781 5.65339 18.3781C5.50749 18.3781 5.39807 18.3052 5.28864 18.1957C3.64731 16.1532 3.75673 13.1258 5.54396 11.1927C5.54396 11.1562 5.54396 11.1562 5.58044 11.1562L8.79016 7.94649C8.93606 7.8006 9.11843 7.76412 9.26433 7.83707C9.4467 7.91002 9.55612 8.09239 9.51965 8.27476C9.48317 9.84315 9.99381 11.3751 11.0151 12.5787C11.161 12.7611 11.161 13.0164 10.9786 13.1988L10.1032 14.0742C9.22785 14.9495 8.79016 15.3872 8.60779 15.6061C8.57132 15.6425 8.49837 15.679 8.4619 15.7155L5.94518 18.2322C5.87223 18.3052 5.76281 18.3781 5.65339 18.3781ZM6.2005 11.7763C4.81448 13.3082 4.66858 15.5696 5.68986 17.2474L7.91478 15.0225C7.95126 14.986 7.98773 14.986 8.02421 14.9495C8.24305 14.6942 8.68074 14.2565 9.4467 13.4906L10.0668 12.8705C9.3008 11.8492 8.79016 10.6091 8.64427 9.33251L6.2005 11.7763Z"
        fill="white"
      />
      <path
        d="M18.3459 18.3788C18.2365 18.3788 18.1271 18.3423 18.0176 18.2329L15.3915 15.6067L11.89 12.1052C10.1027 10.1721 10.0298 7.14472 11.6347 5.10217C11.7076 4.99275 11.817 4.95627 11.9629 4.9198C12.0724 4.9198 12.2182 4.95627 12.2912 5.0657L18.4189 11.1934C20.1696 13.1265 20.279 16.1538 18.6742 18.1964C18.6377 18.3058 18.5283 18.3423 18.3459 18.3788C18.3824 18.3788 18.3824 18.3788 18.3459 18.3788ZM16.048 14.9867L18.273 17.2116C19.3307 15.5338 19.1483 13.2724 17.7988 11.7769L17.7258 11.6675L12.0723 6.0505C11.0146 7.72831 11.197 9.98971 12.5465 11.4851L16.048 14.9867Z"
        fill="white"
      />
    </SvgIcon>
  );
}
