import * as Sentry from "@sentry/browser";
import { PACKAGE_JSON_VERSION } from "./constants";

const errorLogger = (err: any) => {
  // If an error has a 401 status code, that's just the API
  // doing it's job. We don't want to capture those exceptions.
  if (err.response?.status === 401) {
    return;
  }

  // Provide some extra breadcrumbs for helping us figure out issues.
  if (process.env.NODE_ENV !== "test") {
    console.warn(err);
  }
  Sentry.setExtra("whole error", JSON.stringify(err));
  Sentry.setExtra("request url", err.response?.config?.url || err.config?.url);
  Sentry.setExtra(
    "request body",
    err.response?.config?.data || err.config?.data
  );
  Sentry.setExtra("response", JSON.stringify(err.response?.data) || err.data);
  Sentry.setExtra("version number", PACKAGE_JSON_VERSION);

  // Add some tags to help track down support tickets.
  Sentry.setTag("site_id", JSON.stringify(getSiteIdFromURL()));

  Sentry.captureException(err);
};

export const getSiteIdFromURL = (): number | null => {
  const { href } = window.location;
  const match = /sites\/(\d+)/.exec(href);
  return match ? Number(match[1]) : null;
};

export default errorLogger;
