// @ts-ignore

// Ponyfill Object.fromEntries
// https://github.com/feross/fromentries/blob/master/index.js
Object.fromEntries =
  Object.fromEntries ||
  function fromEntries(iterable: any) {
    return [...iterable].reduce((obj, [key, val]) => {
      obj[key] = val;
      return obj;
    }, {});
  };

if (!("IntersectionObserver" in window)) {
  // @ts-ignore No declaration file
  import(/*webpackChunkName: 'io'*/ "intersection-observer");
}
