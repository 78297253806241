import { subDays, isBefore } from "date-fns";
import { SiteDataContext } from "state/context/site/siteCtx";
import React from "react";

const useGA4 = () => {
  const siteCtx = React.useContext(SiteDataContext);
  const validateGA4Date = (dateString: string) => {
    if (!dateString) {
      return false;
    }
    /**
     * * I wrote this out this way on purpose.
     * While we could easily string together this in one line,
     * I wrote it out so the logic is easier to follow and easier to debug
     */

    // we need a date to compare the live_on date. We need to grab yesterdays date, so we start with today
    const todaysDate = new Date();

    // this is the ga4_live_on date
    const originalDate = new Date(dateString);

    // this is the date we actually compare with due to how we collect data
    const beforeThisDate = subDays(originalDate, 1);

    // validation of the two date. Is "beforeThisDate" before "todaysDate". Returns a boolean
    // NOTE: This is a variable so we could easily log this without having to restructure the function into a variable when writing a log.you
    const validatedAnswer = isBefore(beforeThisDate, todaysDate);

    return validatedAnswer;
  };
  return {
    isRunningGA4: validateGA4Date(siteCtx?.ga4_live_on as string),
    status: siteCtx?.analytics_connection_status,
    gateKeepGA4: (
      nonGA4Value: boolean | string | number,
      ga4Value: boolean | string | number
    ) => {
      if (validateGA4Date(siteCtx?.ga4_live_on as string)) {
        return ga4Value;
      } else {
        return nonGA4Value;
      }
    },
  };
};
export default useGA4;
