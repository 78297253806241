import React from "react";
import { getApi } from "helpers/data";
import { DEFAULT_SITE_DATA } from "./defaultSiteData";
import { SiteData } from "./types";
import { useRequest } from "helpers/hooks";
import { GlobalThemeContext } from "helpers/theme";

const siteCtx = async (id: number): Promise<SiteData> => {
  if (id === 0 || id === undefined) {
    return {} as SiteData;
  }

  const api = getApi();

  const { data } = await api.get(`/sites/${id}`);

  const { site } = data;

  const imageInfo = site.images;

  if (process.env.NODE_ENV === "development") {
    // If we have the react app user premiere env var defined, fetch the
    // contents and replace the original premiere accepted var
    if (process.env.REACT_APP_USER_PREMIERE === "true") {
      site.premiere_accepted = true;
    }

    if (process.env.REACT_APP_USER_PRO === "true") {
      site.pro_accepted = true;
    }
  }
  return {
    ...DEFAULT_SITE_DATA,
    ...site,
    givenNotice: site?.given_notice,
    given_notice: undefined,
    lessLazyMode: site?.less_lazy_mode,
    logo: site?.site_logo,
    site_logo: undefined,
    premiere_accepted_on: new Date(site?.premiere_accepted_on),
    ...imageInfo,
  };
};

export const SiteDataContext = React.createContext({
  ...DEFAULT_SITE_DATA,
  activeSite: 0,
  setActiveSite: (v: number) => {},
  refetchSiteData: () => {},
  siteContextError: null,
});

export const SiteDataProvider = (props: any) => {
  const [activeSite, setActiveSite] = React.useState<number>(0);
  const {
    data,
    retry,
    error: siteContextError,
  } = useRequest({
    fetchData: siteCtx,
    initialParams: activeSite,
  });

  const { setCurrentTheme } = React.useContext(GlobalThemeContext);

  React.useEffect(() => {
    // Sets theme
    if (data) {
      if (data.premiere_accepted === true) {
        setCurrentTheme("premiere");
      } else if (data.pro_accepted === true) {
        setCurrentTheme("pro");
      } else {
        setCurrentTheme("default");
      }
    }
  }, [setCurrentTheme, data]);

  const refetchSiteData = () => retry();

  return (
    <SiteDataContext.Provider
      value={{
        ...DEFAULT_SITE_DATA,
        ...data,
        activeSite,
        setActiveSite,
        refetchSiteData,
        siteContextError,
      }}
    >
      {props.children}
    </SiteDataContext.Provider>
  );
};

export default siteCtx;
