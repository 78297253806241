import React, { useCallback } from "react";
import { Notice, Button, Box, Password, Grid } from "@mediavine/ui";
import { FORM_ERROR } from "final-form";
import { Form, Field } from "react-final-form";
import { useLocation, Link } from "react-router-dom";
import AuthLayout from "../../components/AuthLayout";
import errorLogger from "helpers/formLogger";
import { getApi } from "helpers/data";

interface ResetPasswordFormShape {
  password: string;
  password_confirmation: string;
}

const INITIAL_VALUES: ResetPasswordFormShape = {
  password: "",
  password_confirmation: "",
};

function validate(values: ResetPasswordFormShape) {
  const errors: { [k: string]: string } = {};

  if (!values.password || values.password.trim().length === 0) {
    errors.password = "Required";
  }

  if (
    !values.password_confirmation ||
    values.password_confirmation.trim().length === 0
  ) {
    errors.password_confirmation = "Required";
  }

  if (
    values.password &&
    values.password_confirmation &&
    values.password !== values.password_confirmation
  ) {
    errors.password = "Required";
  }

  return errors;
}

export default function EditPassword() {
  const location = useLocation<{ reset_password_token: string }>();

  const handleSubmit = useCallback(
    async (values: ResetPasswordFormShape) => {
      try {
        const api = getApi();
        const params = new URLSearchParams(location.search);
        await api.patch<{ isDashboardUser: boolean }>(`/passwords`, {
          password: values.password,
          password_confirmation: values.password_confirmation,
          reset_password_token: params.get("reset_password_token"),
        });
        return true;
      } catch (e) {
        errorLogger(e);
        return { [FORM_ERROR]: "Could not reset password." };
      }
    },
    [location.search]
  );

  return (
    <AuthLayout title="Reset Your Password">
      <Form<ResetPasswordFormShape>
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={INITIAL_VALUES}
      >
        {(formRenderProps) => (
          <Grid>
            {formRenderProps.submitError && (
              <Notice kind="danger">{formRenderProps.submitError}</Notice>
            )}

            {formRenderProps.submitSucceeded && (
              <Notice kind="success">
                {"Password reset! You can now "}
                <Link to="/">login</Link>.
              </Notice>
            )}
            <Box padding="sm" shadow="box" bg="bodyBg">
              <Field
                name="password"
                render={(fieldRenderProps) => (
                  <Password
                    placeholder="New password"
                    value={fieldRenderProps.input.value}
                    onChange={fieldRenderProps.input.onChange}
                  />
                )}
              />
            </Box>
            <Box padding="sm" shadow="box" bg="bodyBg">
              <Field
                name="password_confirmation"
                render={(fieldRenderProps) => (
                  <Password
                    placeholder="Confirm your new password"
                    value={fieldRenderProps.input.value}
                    onChange={fieldRenderProps.input.onChange}
                  />
                )}
              />
            </Box>
            <Button
              disabled={
                formRenderProps.submitSucceeded ||
                formRenderProps.submitting ||
                formRenderProps.hasValidationErrors
              }
              kind="primary"
              onClick={formRenderProps.handleSubmit}
            >
              Change my password
            </Button>
          </Grid>
        )}
      </Form>
    </AuthLayout>
  );
}
