import React from "react";
import { confirm, Button, Icon } from "@mediavine/ui";
import { useHistory } from "react-router-dom";

/**
 * Renders a cancel button to navigate back a page after
 * confirming. Should only be used ONCE on a page.
 * (Otherwise, we need a dynamic id.)
 */
const CancelButton = ({
  children,
  formStatus,
  redirectURL,
  ...props
}: {
  children?: React.ReactNode;
  formStatus?: boolean;
  redirectURL?: string;
}) => {
  const history = useHistory();
  const redirect = () => {
    if (redirectURL) {
      history.replace(redirectURL);
    } else {
      window.history.back();
    }
  };

  const handleCancel = async (formStatus: boolean | undefined) => {
    if (formStatus === true) {
      redirect();
      return;
    }
    const r = await confirm({
      target: document.querySelector("#go-back") as HTMLElement,
      message: "Are you sure? Changes will be lost.",
    });
    if (r) {
      redirect();
    }
  };

  return (
    <Button
      color="bodyLt"
      {...props}
      id="go-back"
      onClick={() => handleCancel(formStatus)}
    >
      <Icon
        kind="caretLeft"
        size=".5em"
        color="bodyLt"
        style={{ marginBottom: "2px" }}
      />
      {children || "Cancel"}
    </Button>
  );
};

export default CancelButton;
