import React, { useCallback } from "react";
import { Notice, Button, Box, Password, Grid } from "@mediavine/ui";
import { FORM_ERROR } from "final-form";
import { Form, Field } from "react-final-form";
import { useLocation, Link } from "react-router-dom";
import { UserData } from "@mediavine/ui/dist/Network/types";
import AuthLayout from "components/AuthLayout";
import { ILoginData } from "./Login";
import { getApi } from "helpers/data";

interface AcceptInvitationFormShape {
  password: string;
  password_confirmation: string;
}

const INITIAL_VALUES: AcceptInvitationFormShape = {
  password: "",
  password_confirmation: "",
};

function validate(values: AcceptInvitationFormShape) {
  const errors: { [k: string]: string } = {};

  if (!values.password || values.password.trim().length === 0) {
    errors.password = "Required";
  }

  if (
    !values.password_confirmation ||
    values.password_confirmation.trim().length === 0
  ) {
    errors.password_confirmation = "Required";
  }

  if (
    values.password &&
    values.password_confirmation &&
    values.password !== values.password_confirmation
  ) {
    errors.password = "Required";
  }

  return errors;
}

interface AcceptInvitationResponse {
  data: {
    token: string;
    user: UserData & { twilio_verify_enabled: boolean };
  };
}

interface EditPasswordProps {
  onLogin(data: ILoginData): void;
}

export default function EditPassword(props: EditPasswordProps) {
  const { onLogin } = props;
  const location = useLocation<{ reset_password_token: string }>();

  const handleSubmit = useCallback(
    (values: AcceptInvitationFormShape) => {
      const api = getApi();
      const params = new URLSearchParams(location.search);
      return api
        .patch<AcceptInvitationResponse>(`/invitations`, {
          password: values.password,
          password_confirmation: values.password_confirmation,
          invitation_token: params.get("invitation_token"),
        })
        .then((res) => {
          const token = res.data.data.token;
          const user = res.data.data.user;
          const twoFactorRequired = res.data.data.user.twilio_verify_enabled;
          onLogin({
            data: {
              token,
              user,
              twoFactorRequired,
            },
          });
          return undefined; // This lets final-form know there were no errors
        })
        .catch((e) => {
          return { [FORM_ERROR]: "Could not set your password." };
        });
    },
    [location.search, onLogin]
  );

  return (
    <AuthLayout title="Set Your Password">
      <Form<AcceptInvitationFormShape>
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={INITIAL_VALUES}
      >
        {(formRenderProps) => (
          <Grid>
            {formRenderProps.submitError && (
              <Notice kind="danger">{formRenderProps.submitError}</Notice>
            )}

            {formRenderProps.submitSucceeded && (
              <Notice kind="success">
                {"Password reset! You can now "}
                <Link to="/">login</Link>.
              </Notice>
            )}
            <Box padding="sm" shadow="box" bg="bodyBg">
              <Field
                name="password"
                render={(fieldRenderProps) => (
                  <Password
                    placeholder="Password"
                    value={fieldRenderProps.input.value}
                    onChange={fieldRenderProps.input.onChange}
                  />
                )}
              />
            </Box>
            <Box padding="sm" shadow="box" bg="bodyBg">
              <Field
                name="password_confirmation"
                render={(fieldRenderProps) => (
                  <Password
                    placeholder="Confirm your new Password"
                    value={fieldRenderProps.input.value}
                    onChange={fieldRenderProps.input.onChange}
                  />
                )}
              />
            </Box>
            <Button
              disabled={
                formRenderProps.submitting ||
                formRenderProps.hasValidationErrors
              }
              kind="primary"
              onClick={formRenderProps.handleSubmit}
            >
              Set my password
            </Button>
          </Grid>
        )}
      </Form>
    </AuthLayout>
  );
}
