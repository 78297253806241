import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/browser";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { GlobalThemeProvider } from "helpers/theme";
import "helpers/polyfills";
import { __DEV_ONLY__simulateDate } from "helpers/dates";
import "@reach/listbox/styles.css";
import "react-day-picker/lib/style.css";
import ThemeProvider from "theme";
import { unregisterSW } from "sw";
import { store } from "./store";
import { Provider } from "react-redux";
import ConfirmDialogue from "views/Settings/user/components/confirmDialogue";

unregisterSW();

// Initialize sentry on page load
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://f391c762c8fa4d6f983e268656aa99dd@sentry.io/1776059",
  });
}

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <Provider store={store}>
    <GlobalThemeProvider>
      <ThemeProvider>
        <Router basename={process.env.PUBLIC_URL}>
          <App />
          <ConfirmDialogue />
        </Router>
      </ThemeProvider>
    </GlobalThemeProvider>
  </Provider>
);

if (process.env.REACT_APP_FAKE_DATE) {
  __DEV_ONLY__simulateDate(process.env.REACT_APP_FAKE_DATE);
}
