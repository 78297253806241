import React from "react";
import { Flex, ScreenReader, Spinner } from "@mediavine/ui";

export default function DataPlaceholder() {
  return (
    <Flex padding="xl" justify="center">
      <ScreenReader>Loading</ScreenReader>
      <Spinner />
    </Flex>
  );
}
