import React from "react";
import { Snackbar, Alert, Typography, Slide, Grid, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RenderIf from "components/RenderIf";
import { reset } from "store/globalToast";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";

const TransitionLeft = (props: any) => {
  return <Slide {...props} direction="up" />;
};

const ErrorToast: React.FC = () => {
  const reducerState = useSelector((state: RootState) => state?.globalToast);
  const dispatch = useDispatch();

  return (
    <Snackbar
      sx={{ marginBottom: "48px" }}
      TransitionComponent={TransitionLeft}
      open={reducerState.isShowing}
      onClose={() => dispatch(reset())}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert severity="error" style={{ alignItems: "center" }}>
        <Grid container alignItems={"center"} spacing={2} overflow="auto">
          <Grid item>
            <Typography>{reducerState.toastMessage}</Typography>
          </Grid>
          <Grid item alignItems={"center"}>
            <Box
              onClick={() =>
                navigator.clipboard.writeText(
                  `Error Code: ${reducerState.errorCode}`
                )
              }
              sx={{ "&:hover": { cursor: "pointer" } }}
              alignItems="center"
              display="flex"
            >
              <Typography color="GrayText" alignItems="center" display="flex">
                <ContentCopyIcon
                  sx={{ fontSize: "16px", marginRight: "8px" }}
                />
                {reducerState.errorCode}
              </Typography>
            </Box>
          </Grid>
          <RenderIf isTrue={reducerState.shouldBeDismissable}>
            <Grid item alignItems={"center"}>
              <Box
                onClick={() => dispatch(reset())}
                sx={{ "&:hover": { cursor: "pointer" } }}
                alignItems="center"
              >
                <CloseIcon color="disabled" />
              </Box>
            </Grid>
          </RenderIf>
        </Grid>
      </Alert>
    </Snackbar>
  );
};

export default ErrorToast;
