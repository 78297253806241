import React, { useContext } from "react";
import { Flex, Grid, Text, Network } from "@mediavine/ui";
import { useClickwrap } from "helpers/hooks";
import { SiteDataContext } from "state/context/site/siteCtx";
import RenderIf from "components/RenderIf";
import { useHasRole } from "components/RequiresRole";
import axios from "axios";
import LoginModal from "components/modals/LoginModal";
import { PSClickWrap } from "@pactsafe/pactsafe-react-sdk";
import {
  LinearProgress,
  Button as MuiButton,
  Typography,
  Alert,
} from "@mui/material";
import { DefaultTheme, makeStyles } from "@mui/styles";
import { GlobalThemeContext } from "helpers/theme";
import { CLICKWRAP_ACCESS_ID } from "helpers/constants";

const useStyles = makeStyles<DefaultTheme, { isDarkMode: boolean }>(() => ({
  clickwrap: (props) => ({
    "& .ps-contract-body b, .ps-contract-body ol .ps-section, #ps-clickwrap div, #ps-clickwrap span, #ps-clickwrap strong, #ps-clickwrap p":
      props.isDarkMode
        ? {
            color: "#fff !important",
          }
        : undefined,
    "& .ps-contract-options, .ps-checkbox-container": props.isDarkMode
      ? {
          backgroundColor: "#3c3c3c !important",
        }
      : undefined,
  }),
}));

function TosModal({
  gkey,
  contractId,
  isBaseTos,
  isSignatureRequired,
}: {
  gkey: string;
  contractId: number;
  isBaseTos?: boolean;
  isSignatureRequired: boolean;
}) {
  const { id, accepted_terms_of_service, refetchSiteData, offering_id, title } =
    React.useContext(SiteDataContext);
  const { isDarkMode } = useContext(GlobalThemeContext);
  const {
    userData,
    userData: { roles, id: userId, name, email },
    apiParams,
    // @ts-ignore
  } = React.useContext<UserContextProps>(Network.JWTContext);
  const [closeTos, setCloseTos] = React.useState(false);
  const offering = offering_id === 2 ? "Pubnation" : "Mediavine";
  const { acceptTos, error } = useClickwrap({
    gkey,
    contractId,
  });

  const { clickwrap } = useStyles({ isDarkMode });

  const handleAcceptedTos = async () => {
    // Needs looked into and see why the type error is being thrown
    //@ts-ignore
    const api = axios.create(apiParams);
    if (!accepted_terms_of_service && isBaseTos) {
      await api
        .patch(`/sites/${id}/site_settings/profile_settings`, {
          accepted_terms_of_service: true,
        })
        .then(() => {
          refetchSiteData();
        });
    }

    await acceptTos("agreed", {
      site_id: id.toString(),
      user_id: userId,
      site_name: title,
      email: email,
      name: name,
      offering: offering,
    });
    if (!error) {
      setCloseTos(true);
    }
  };

  const [contractRendered, setContractRendered] = React.useState(false);
  const [hasAgreed, setHasAgreed] = React.useState(false);
  const [hasRole] = useHasRole(userData, ["owner", "ad_settings"], id);

  return (
    <>
      <LoginModal
        open={isSignatureRequired && !closeTos && !roles.includes("admin")}
      >
        {error && <Alert severity="error">{error}</Alert>}
        <RenderIf isTrue={hasRole}>
          {!contractRendered && <LinearProgress color="primary" />}
          <div
            style={{ visibility: contractRendered ? "visible" : "hidden" }}
            className={clickwrap}
          >
            <PSClickWrap
              accessId={CLICKWRAP_ACCESS_ID}
              groupKey={gkey}
              signerIdSelector={`${id}`}
              allowDisagreed={false}
              forceScroll={true}
              clickWrapStyle="scroll"
              dynamic
              renderData={{
                offering: offering,
              }}
              onAll={(e: any) => {
                if (e === "rendered") {
                  setContractRendered(true);
                }
                if (e === "checked") {
                  setHasAgreed(true);
                }
              }}
            />
            <Flex justify="center">
              <MuiButton
                color="primary"
                variant="contained"
                onClick={handleAcceptedTos}
                disabled={!hasAgreed}
              >
                Accept Terms of Service
              </MuiButton>
            </Flex>
            <Typography align="center" color="disabled" sx={{ mt: 2 }}>
              Contact support if you have more questions
            </Typography>
          </div>
        </RenderIf>
        <RenderIf isTrue={!hasRole}>
          <Grid gutter="md" padding="lg">
            <Text>
              You are unable to proceed because Mediavine’s Terms of Service
              have not yet been accepted for this site. Only the site owner can
              accept the Terms of Service, so please notify the owner of this
              site to login with their account and accept those Terms. Once this
              is done you will have access to the dashboard.
            </Text>
          </Grid>
        </RenderIf>
      </LoginModal>
    </>
  );
}

export default TosModal;
