import React from "react";
import { SiteDataContext } from "state/context/site/siteCtx";
import TosModal from "components/modals/TosModal";
import ProModal from "components/modals/ProModal";
import BusinessInformationModal from "components/modals/BusinessInformationModal";
import ConfirmAddressModal from "components/modals/ConfirmAddressModal";
import PaymentModal from "components/modals/PaymentModal";
import AnalyticsAuthDisconnectedModal from "components/modals/AnalyticsAuthDisconnectedModal";
import RenderIf from "./RenderIf";
import {
  DASHBOARD_CONTRACT_ID,
  DASHBOARD_GROUP_KEY,
  PRO_GROUP_KEY,
  PREMIERE_GROUP_KEY,
  PRO_CONTRACT_ID,
  PREMIERE_CONTRACT_ID,
} from "helpers/constants";
import { fetchSignature } from "helpers/hooks/useClickwrap";
import { useFeatureFlag } from "helpers/hooks";
import { matchPath, useLocation } from "react-router-dom";

export function LoginModals() {
  const siteDataCtx = React.useContext(SiteDataContext);
  const {
    owned,
    accepted_terms_of_service,
    pro_accepted,
    premiere_accepted,
    id,
  } = siteDataCtx;
  const [tosState, setTosState] = React.useState<{
    isLoading: boolean;
    hasAccepted: boolean;
  }>({
    isLoading: true,
    hasAccepted: false,
  });
  const isShowingAddendums = useFeatureFlag("show_tier_addendums");
  const gkey =
    !accepted_terms_of_service && !premiere_accepted && !pro_accepted
      ? DASHBOARD_GROUP_KEY
      : premiere_accepted
        ? PREMIERE_GROUP_KEY
        : PRO_GROUP_KEY;
  const { pathname } = useLocation();
  const isPaymentsPage = Boolean(
    matchPath(pathname, { path: "/sites/:id/payments", exact: true })
  );

  React.useEffect(() => {
    async function getSignature() {
      const isSignatureRequired = await fetchSignature({
        gkey,
        siteId: id,
      });
      setTosState({ isLoading: false, hasAccepted: isSignatureRequired });
    }
    getSignature();
  }, [id, pro_accepted, premiere_accepted, gkey]);

  // Don't display login modals for sites that Mediavine owns
  if (owned) {
    return null;
  }

  return (
    <>
      <RenderIf
        isTrue={
          !accepted_terms_of_service &&
          !pro_accepted &&
          !premiere_accepted &&
          !tosState.isLoading
        }
      >
        <TosModal
          contractId={DASHBOARD_CONTRACT_ID}
          gkey={DASHBOARD_GROUP_KEY}
          isBaseTos={true}
          isSignatureRequired={!tosState.hasAccepted}
        />
      </RenderIf>
      <RenderIf
        isTrue={
          accepted_terms_of_service &&
          Boolean(pro_accepted) &&
          !tosState.hasAccepted &&
          Boolean(isShowingAddendums) &&
          !tosState.isLoading
        }
      >
        <TosModal
          contractId={PRO_CONTRACT_ID}
          gkey={PRO_GROUP_KEY}
          isSignatureRequired={!tosState.hasAccepted}
        />
      </RenderIf>
      <RenderIf
        isTrue={
          accepted_terms_of_service &&
          Boolean(premiere_accepted) &&
          !tosState.hasAccepted &&
          Boolean(isShowingAddendums) &&
          !tosState.isLoading
        }
      >
        <TosModal
          contractId={PREMIERE_CONTRACT_ID}
          gkey={PREMIERE_GROUP_KEY}
          isSignatureRequired={!tosState.hasAccepted}
        />
      </RenderIf>
      <RenderIf isTrue={accepted_terms_of_service}>
        {/* If the user is active but the ganalytics state is disconnected we show a modal allowing him to reconnect */}
        <AnalyticsAuthDisconnectedModal />
        <BusinessInformationModal />
        <ConfirmAddressModal />
        {!isPaymentsPage && <PaymentModal />}
        <ProModal />
      </RenderIf>
    </>
  );
}
