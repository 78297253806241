import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  Button,
  Dropdown,
  Flex,
  Grid,
  H6,
  Icon,
  ScreenReader,
  Text,
  Input,
  InputGroup,
} from "@mediavine/ui";
import ExternalLink from "components/ExternalLink";
import { AdminBarContext } from "helpers/context";
import { useIsMobile } from "helpers/hooks";
import cache from "helpers/cache";
import { FeatureFlagContext } from "components/FeatureFlag";
import { Role, userRoles } from "views/Settings/Users";
import RenderIf from "../../components/RenderIf";
import { SiteDataContext } from "state/context/site/siteCtx";

function AdminBar({ siteId, ownerId, formData, handleSubmit }: AdminBarProps) {
  const { domain, features, id } = React.useContext(SiteDataContext);
  const { flags: userFlags } = React.useContext(FeatureFlagContext);
  const history = useHistory();
  const location = useLocation();

  const [showEnabledFeatures, setShowEnabledFeatures] = useState(true);
  const [selectedRole, setSelectedRole] = useState<Role>("admin");
  const [showRole, setShowRole] = useState(true);
  const [findFeature, setFindFeature] = useState<string>();
  const didMount = useRef(false);
  const isMobile = useIsMobile();
  const adminLink = process.env.REACT_APP_API?.split("/api/")[0];

  const { isAdminBarOpen, setIsAdminBarOpen, setSimulatedUserData } =
    useContext(AdminBarContext);

  const siteFlagsFiltered = features
    .filter(filterNoise)
    .filter((val) => (findFeature ? val.includes(findFeature) : val));
  const userFlagsFiltered = userFlags
    .filter(filterNoise)
    .filter((val) => (findFeature ? val.includes(findFeature) : val));

  const userData = cache.get("userData");

  const [adminBarHeight, setAdminBarHeight] = useState<number | undefined>(0);

  const setPath = (role: string) => {
    switch (role) {
      case "ad_settings":
        return `/sites/${siteId}/settings/ad-settings`;
      case "video":
        return `/sites/${siteId}/videos`;
      case "payment":
        return `/sites/${siteId}/payments`;
      case "owner":
        return `${location.pathname}`;
      case "admin":
        return `${location.pathname}`;
      default:
        return `/sites/${siteId}`;
    }
  };

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }

    // If selecting admin, we clear the simulated data since we are not simulating anymore
    if (selectedRole === "admin") {
      setSimulatedUserData(null);
    } else {
      setSimulatedUserData({ ...userData, roles: [selectedRole] });
    }

    history.push(`${setPath(selectedRole)}`);

    // eslint-disable-next-line
  }, [selectedRole]);

  const isAdminAccessOwnerRestricted = (role: Role) => {
    return (
      role === "owner" &&
      (location.pathname.includes("/settings/uplift") ||
        location.pathname.includes("/profile"))
    );
  };

  return (
    <>
      <RenderIf isTrue={!isMobile}>
        {isAdminBarOpen ? (
          <Container
            ref={(e) => {
              setAdminBarHeight(e?.getBoundingClientRect()?.height);
            }}
          >
            <Flex>
              <Flex gutter={"md"}>
                <Dropdown.Wrapper id="enabled-features-list" width="auto">
                  {() => (
                    <>
                      <InverseButton
                        inverse
                        onClick={() => {
                          setShowEnabledFeatures(!showEnabledFeatures);
                        }}
                      >
                        <Text color="#fff">Enabled Features</Text>
                      </InverseButton>
                      <Dropdown.Items>
                        {/* @ts-ignore */}
                        <DropupList
                          width={"750px"}
                          hidden={showEnabledFeatures}
                          offSet={adminBarHeight}
                        >
                          <Grid gutter="md" allowChildBlowout>
                            <InputGroup>
                              <Icon kind="search" color="border" size="sm" />
                              <Input
                                value={findFeature}
                                onChange={(val: string) => {
                                  setFindFeature(val);
                                }}
                                placeholder="Search feature..."
                              />
                            </InputGroup>
                            <Flex justify="space-between" align="flex-start">
                              <Grid gutter="xs">
                                <H6>Site Features</H6>
                                {siteFlagsFiltered.length < 1 && (
                                  <Text>No features found</Text>
                                )}
                                {siteFlagsFiltered.map((feature) => (
                                  <Text key={`${feature}`}>- {feature}</Text>
                                ))}
                              </Grid>

                              <Grid gutter="xs" allowChildBlowout>
                                <H6>User Features</H6>
                                {userFlagsFiltered.length < 1 && (
                                  <Text>No features found</Text>
                                )}
                                {userFlagsFiltered.map((feature) => (
                                  <Text key={`${feature}`}>- {feature}</Text>
                                ))}
                              </Grid>
                            </Flex>
                          </Grid>
                        </DropupList>
                      </Dropdown.Items>
                    </>
                  )}
                </Dropdown.Wrapper>
                <Dropdown.Wrapper id="user-role-selection" width="auto">
                  {() => (
                    <>
                      <Button
                        onClick={() => {
                          setShowRole(!showRole);
                        }}
                        style={{
                          minWidth: "175px",
                        }}
                        inverse
                        color="black"
                        disabled={!siteId}
                      >
                        <div
                          style={{
                            display: "flex",
                            minWidth: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <Text color="#fff">{selectedRole}</Text>
                          <Icon
                            kind={showRole ? "caretDown" : "caretUp"}
                            color="#fff"
                          />
                        </div>
                      </Button>
                      <Dropdown.Items>
                        {/* @ts-ignore */}
                        <DropupList
                          width={"175px"}
                          hidden={showRole}
                          offSet={adminBarHeight}
                        >
                          <Flex justify="space-between">
                            <Grid gutter="xs" allowChildBlowout>
                              {userRoles.map((role) => {
                                if (isAdminAccessOwnerRestricted(role)) {
                                  return <></>;
                                }
                                return (
                                  <Dropdown.Item
                                    key={role}
                                    onClick={() => {
                                      setSelectedRole(role);
                                      setShowRole(!showRole);
                                    }}
                                  >
                                    {role}
                                  </Dropdown.Item>
                                );
                              })}
                            </Grid>
                          </Flex>
                        </DropupList>
                      </Dropdown.Items>
                    </>
                  )}
                </Dropdown.Wrapper>
              </Flex>
              <Flex justify="center">
                <Button kind="link">
                  <ExternalLink href={`http://${domain}`}>
                    <Flex
                      justify="space-evenly"
                      flex="1 0 auto"
                      width="100px"
                      color="white"
                    >
                      <Icon kind="globe" color="white" />
                      View Site
                    </Flex>
                  </ExternalLink>
                </Button>
                <Button kind="link">
                  <ExternalLink
                    href={
                      id
                        ? `${adminLink}/admin/site/${id}/edit`
                        : `${adminLink}/admin`
                    }
                  >
                    <Flex
                      justify="space-evenly"
                      flex="1 0 auto"
                      width="100px"
                      color="white"
                    >
                      <Icon kind="account" color="white" />
                      <span>Admin</span>
                    </Flex>
                  </ExternalLink>
                </Button>
                <Button
                  kind="icon"
                  onClick={() => setIsAdminBarOpen(!isAdminBarOpen)}
                >
                  <ScreenReader>More options</ScreenReader>
                  <Icon
                    kind="caretDown"
                    color="white"
                    style={{ height: "24px" }}
                  />
                </Button>
              </Flex>
            </Flex>
          </Container>
        ) : (
          <CollapsedAdminBar onClick={setIsAdminBarOpen} />
        )}
      </RenderIf>
    </>
  );
}

function CollapsedAdminBar({
  onClick,
}: {
  onClick: (isOpen: boolean) => void;
}) {
  return (
    <CollapsedButton>
      <Button kind="icon" onClick={() => onClick(true)}>
        <Icon kind="dot" size="lg" color="body" />
      </Button>
    </CollapsedButton>
  );
}

function filterNoise(flag: string) {
  /**
   * These flags are turned on for everyone, so we don't want to crowd the notice with them.
   */
  const noise = [
    "payment_modal",
    "confirm_payee_name",
    "ads_in_lists",
    "adhesion_close",
    "psa",
    "default_vtt",
  ];
  return !noise.includes(flag);
}

interface AdminBarProps {
  siteId: number | null;
  ownerId?: number;
  formData?: any;
  handleSubmit?: any;
  toggleAdminView?: any;
  isAdminView?: boolean;
}

const CollapsedButton = styled.div`
  position: absolute;
  right: 24%;
  bottom: 3%;
`;

const Container = styled.div`
  background-color: #000;
  bottom: 0;
  padding: 12px;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 10000;
`;

const DropupList: any = styled.div`
  background-color: ${(props) => props.theme.colors.body};
  color: ${(props) => props.theme.colors.white};
  position: ${(props) => (props.hidden ? "absolute" : "fixed")};
  bottom: ${(props) =>
    // @ts-ignore
    props.offSet ? props.offSet + "px" : "0"};
  display: block;
  width: ${(props) =>
    // @ts-ignore
    props.width};
  padding: ${(props) => props.theme.sizes.sm};
  max-height: 50vh;
  overflow-y: auto;
`;

const InverseButton = styled(Button)`
  &:hover {
    background-color: ${(props) => props.theme.colors.white} !important;

    > * {
      color: ${(props) => props.theme.colors.bodyLt} !important;
    }
  }
`;

export default AdminBar;
