import { Theme } from "@mui/material";
import Button from "./Button";
import Divider from "./Divider";
import Stepper from "./Stepper";
import TextField from "./TextField";
import Alert from "./Alert";
import Select from "./Select";
import Modal from "./Modal";
import Autocomplete from "./Autocomplete";

export default function ComponentOverrides(theme: Theme) {
  return Object.assign(
    Button(theme),
    Divider(theme),
    TextField(theme),
    Stepper(theme),
    Alert(theme),
    Select(theme),
    Modal(theme),
    Autocomplete(theme)
  );
}
