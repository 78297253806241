import React from "react";
import { Button, Network } from "@mediavine/ui";
import { ButtonProps } from "@mediavine/ui/dist/Button";

export default function LogoutButton(props: ButtonProps) {
  const { onLogout } = React.useContext(Network.JWTContext);
  return (
    <Button onClick={onLogout} size="sm" kind="link" {...props}>
      {props.children || "Sign out"}
    </Button>
  );
}
