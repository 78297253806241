import useIsAdmin from "helpers/hooks/useIsAdmin";
import React from "react";
import styled, { createGlobalStyle } from "styled-components";

interface NoNavigationProps {
  children: any;
  useWrapper?: boolean;
}

/**
 * Used to wrap any view that shouldn't display a navbar.
 * This will hide the navbar. This is useful if you need
 * to render a view inside of the router, since it's wrapped
 * inside the navigation component.
 */
export default function NoNavigation({
  children,
  useWrapper = true,
}: NoNavigationProps) {
  const isAdmin = useIsAdmin();
  const isStaging = process.env.NODE_ENV === "staging";

  return (
    <>
      <NoNavigationStyles />
      {isAdmin || isStaging || !useWrapper ? (
        <>{children}</>
      ) : (
        <NoNavigationWrap>{children}</NoNavigationWrap>
      )}
    </>
  );
}

/**
 * Used to wrap navigation views. If a child with NoNavigation is present
 * in the tree, this will be hidden.
 */
export function HideableNavigation({ children }: NoNavigationProps) {
  return <div data-navigation>{children}</div>;
}

const NoNavigationStyles = createGlobalStyle`
[data-navigation] {
  display: none;
}
`;
const NoNavigationWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
  > * {
    min-height: 100%;
    width: 100%;
  }
`;
