import { SvgIcon } from "@mui/material";
import React from "react";

export default function ShineLogoDark(
  props: React.ComponentProps<typeof SvgIcon>
) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 157.2 157.2"
      fill="none"
      {...props}
    >
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 157.2 157.2"
      >
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <path
                        fill="#FFFFFF"
                        d="M78.6,10.8c15,0,28.8,4.8,40,13.1c0.1,0.1,0.2,0.2,0.4,0.3c6.1,4.5,11.5,10.1,15.7,16.4
                        c0.3,0.4,0.5,0.8,0.8,1.2c6.9,10.6,10.9,23.3,10.9,36.9c0,37.4-30.4,67.8-67.8,67.8c-37.4,0-67.8-30.4-67.8-67.8
                        C10.8,41.1,41.1,10.8,78.6,10.8 M78.6,4.1C37.5,4.1,4.1,37.5,4.1,78.6s33.4,74.5,74.5,74.5s74.5-33.4,74.5-74.5
                        c0-14.4-4.1-28.4-12-40.5c-0.3-0.4-0.6-0.9-0.9-1.3c-4.7-6.9-10.5-12.9-17.2-17.9c-0.1-0.1-0.2-0.2-0.3-0.2l-0.1-0.1l-0.1,0
                        l-0.1,0C109.7,9.1,94.5,4.1,78.6,4.1L78.6,4.1z"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g>
            <g>
              <path
                fill="#FFFFFF"
                d="M60.7,93.5c-0.3-0.4-7.1-9.3-15.9-9.2c-8.8,0-15.5,9-15.8,9.3L28,95h33.7l0.1-0.1L60.7,93.5z"
              />
            </g>
            <g>
              <path
                fill="#FFFFFF"
                d="M129.2,95l-1.1-1.4c-0.3-0.4-7.1-9.3-15.9-9.2c-8.8,0-15.5,9-15.8,9.3l-1,1.3L129.2,95L129.2,95z"
              />
            </g>
            <g>
              <path
                fill="#FFFFFF"
                d="M77.2,77.1l1.4,1.1l1.4-1.1c0.4-0.3,9.3-7.1,9.2-15.9c0-8.8-9-15.5-9.3-15.8l-1.4-1.1l-1.4,1.1
                c-0.4,0.3-9.3,7.1-9.2,15.9C67.9,70.1,76.9,76.8,77.2,77.1z"
              />
            </g>
            <g>
              <path
                fill="#FFFFFF"
                d="M109.3,63.1c0.4,0,0.8,0,1.1,0c0.2,3.3-0.3,9.2-3.5,12.4c-3.1,3.1-8.4,3.6-11.3,3.6c-0.4,0-0.8,0-1.1,0
                c-0.2-3.3,0.3-9.2,3.5-12.4C101.1,63.6,106.4,63.1,109.3,63.1 M109.3,58.7c-3.9,0-10.2,0.7-14.4,4.9c-6.2,6.2-4.6,17.3-4.6,17.8
                l0.3,1.8l1.8,0.2c0.2,0,1.5,0.2,3.3,0.2c3.9,0,10.2-0.7,14.4-4.9c6.2-6.2,4.6-17.3,4.6-17.8l-0.3-1.8l-1.8-0.2
                C112.5,58.9,111.2,58.7,109.3,58.7L109.3,58.7z"
              />
            </g>
            <g>
              <path
                fill="#FFFFFF"
                d="M65,83.3l1.8-0.3l0.2-1.8c0.1-0.5,1.5-11.6-4.7-17.7C56,57.3,44.9,58.9,44.5,59l-1.8,0.3L42.5,61
                c-0.1,0.5-1.5,11.6,4.7,17.8C53.4,84.9,64.5,83.4,65,83.3z"
              />
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}
