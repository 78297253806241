import React from "react";
import styled from "styled-components";
import { ScreenReader, ScrollWrap } from "@mediavine/ui";

interface IWrapProps {
  open: boolean;
}

const MobileExpandedWrap = styled.div<IWrapProps>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.5);
  opacity: ${(props) => (props.open ? 1 : 0)};
  transition: opacity 0.2s ease ${(props) => (props.open ? "0s" : ".1s")};
  pointer-events: ${(props) => (props.open ? undefined : "none")};

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.body};
  }
`;
const MobileExpandedWrapInner = styled.div<IWrapProps>`
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${(props) => (props.open ? "0" : "-100vw")};
  background: ${(props) => props.theme.colors.boxBg};
  box-shadow: ${(props) => props.theme.shadows.box};
  transition: left 0.3s ease ${(props) => (props.open ? ".1s" : "0s")};

  nav {
    position: static;
  }

  // Hacks for ScrollWrap
  > div {
    height: 100%;

    > [data-scrollwrap-child] {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
`;
const MobileCloseButton = styled.button`
  position: absolute;
  top: 5px;
  left: calc(100% + 8px);
  padding: 0;
  background: white;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  font-size: 20px;
  border: none;
  line-height: 0;
  color: ${(props) => props.theme.colors.primary};
`;

interface IMobileSideMenuProps {
  children: React.ReactNode;
  open: boolean;
  onToggle(value: boolean): void;
}

export default function MobileSideMenu({
  children,
  open,
  onToggle,
}: IMobileSideMenuProps) {
  return (
    <MobileExpandedWrap open={open}>
      <MobileExpandedWrapInner open={open}>
        <ScrollWrap maxHeight="100vh">
          <MobileCloseButton onClick={() => onToggle(false)}>
            &times;
            <ScreenReader>Close</ScreenReader>
          </MobileCloseButton>
          {children}
        </ScrollWrap>
      </MobileExpandedWrapInner>
    </MobileExpandedWrap>
  );
}
