import { apiService as api } from "./dashboard-api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query<GetUserApiResponse, GetUserApiArg>({
      query: (queryArg) => ({ url: `/api/v1/users/${queryArg.id}` }),
    }),
    listCountries: build.query<ListCountriesApiResponse, ListCountriesApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/countries`,
        params: { name: queryArg.name, code: queryArg.code },
      }),
    }),
    getCountry: build.query<GetCountryApiResponse, GetCountryApiArg>({
      query: (queryArg) => ({ url: `/api/v1/countries/${queryArg.id}` }),
    }),
    signIn: build.mutation<SignInApiResponse, SignInApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/users/sign_in`,
        method: "POST",
        body: queryArg.reqSessionsSignInDto,
      }),
    }),
    listUserFeatures: build.query<
      ListUserFeaturesApiResponse,
      ListUserFeaturesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/features/users/${queryArg.userId}`,
      }),
    }),
    listSites: build.query<ListSitesApiResponse, ListSitesApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sites`,
        params: {
          sort: queryArg.sort,
          direction: queryArg.direction,
          start_date: queryArg.startDate,
          end_date: queryArg.endDate,
          page: queryArg.page,
          per_page: queryArg.perPage,
          slug: queryArg.slug,
          site_ids: queryArg.siteIds,
          query: queryArg.query,
        },
      }),
    }),
    getSite: build.query<GetSiteApiResponse, GetSiteApiArg>({
      query: (queryArg) => ({ url: `/api/v1/sites/${queryArg.id}` }),
    }),
    listSitesLight: build.query<
      ListSitesLightApiResponse,
      ListSitesLightApiArg
    >({
      query: () => ({ url: `/api/v1/sites/light_search` }),
    }),
    generateSignature: build.query<
      GenerateSignatureApiResponse,
      GenerateSignatureApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sites/${queryArg.siteId}/generate_signature`,
        params: { resource_type: queryArg.resourceType },
      }),
    }),
    listOptouts: build.query<ListOptoutsApiResponse, ListOptoutsApiArg>({
      query: () => ({ url: `/api/v1/optouts` }),
    }),
    listCategories: build.query<
      ListCategoriesApiResponse,
      ListCategoriesApiArg
    >({
      query: () => ({ url: `/api/v1/categories` }),
    }),
    listPsAs: build.query<ListPsAsApiResponse, ListPsAsApiArg>({
      query: () => ({ url: `/api/v1/psas` }),
    }),
    listSiteUsers: build.query<ListSiteUsersApiResponse, ListSiteUsersApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sites/${queryArg.siteId}/site_users`,
      }),
    }),
    createSiteUser: build.mutation<
      CreateSiteUserApiResponse,
      CreateSiteUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sites/${queryArg.siteId}/site_users`,
        method: "POST",
        body: queryArg.createSiteUserBodyDto,
      }),
    }),
    updateSiteUser: build.mutation<
      UpdateSiteUserApiResponse,
      UpdateSiteUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sites/${queryArg.siteId}/site_users/${queryArg.siteUserId}`,
        method: "PATCH",
        body: queryArg.updateSiteUserBodyDto,
      }),
    }),
    deleteSiteUser: build.mutation<
      DeleteSiteUserApiResponse,
      DeleteSiteUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sites/${queryArg.siteId}/site_users/${queryArg.siteUserId}`,
        method: "DELETE",
      }),
    }),
    getReportsMetricsSummary: build.query<
      GetReportsMetricsSummaryApiResponse,
      GetReportsMetricsSummaryApiArg
    >({
      query: () => ({ url: `/api/v1/reports/metrics/summary` }),
    }),
    listPlaylists: build.query<ListPlaylistsApiResponse, ListPlaylistsApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sites/${queryArg.siteId}/playlists`,
        params: {
          per_page: queryArg.perPage,
          query: queryArg.query,
          page: queryArg.page,
        },
      }),
    }),
    createPlaylist: build.mutation<
      CreatePlaylistApiResponse,
      CreatePlaylistApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sites/${queryArg.siteId}/playlists`,
        method: "POST",
        body: queryArg.createPlaylistDto,
      }),
    }),
    getPlaylist: build.query<GetPlaylistApiResponse, GetPlaylistApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sites/${queryArg.siteId}/playlists/${queryArg.id}`,
      }),
    }),
    updatePlaylist: build.mutation<
      UpdatePlaylistApiResponse,
      UpdatePlaylistApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sites/${queryArg.siteId}/playlists/${queryArg.id}`,
        method: "PATCH",
      }),
    }),
    deletePlaylist: build.mutation<
      DeletePlaylistApiResponse,
      DeletePlaylistApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sites/${queryArg.siteId}/playlists/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listVideos: build.query<ListVideosApiResponse, ListVideosApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sites/${queryArg.siteId}/videos`,
        params: {
          sort: queryArg.sort,
          sort_order: queryArg.sortOrder,
          query: queryArg.query,
          page: queryArg.page,
          per_page: queryArg.perPage,
          priority: queryArg.priority,
        },
      }),
    }),
    createVideo: build.mutation<CreateVideoApiResponse, CreateVideoApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sites/${queryArg.siteId}/videos`,
        method: "POST",
        body: queryArg.reqCreateVideoDto,
      }),
    }),
    getVideo: build.query<GetVideoApiResponse, GetVideoApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sites/${queryArg.siteId}/videos/${queryArg.slug}`,
      }),
    }),
    reprocessVideo: build.mutation<
      ReprocessVideoApiResponse,
      ReprocessVideoApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sites/${queryArg.siteId}/videos/${queryArg.slug}/reprocess`,
        method: "PATCH",
      }),
    }),
    getMcmChildPublisher: build.query<
      GetMcmChildPublisherApiResponse,
      GetMcmChildPublisherApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/mcm_child_publishers/${queryArg.id}`,
      }),
    }),
    getMcmGamSite: build.query<GetMcmGamSiteApiResponse, GetMcmGamSiteApiArg>({
      query: (queryArg) => ({ url: `/api/v1/mcm_gam_sites/${queryArg.id}` }),
    }),
    getProfileSettings: build.query<
      GetProfileSettingsApiResponse,
      GetProfileSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sites/${queryArg.siteId}/site_settings/profile_settings`,
      }),
    }),
    updateProfileSettings: build.mutation<
      UpdateProfileSettingsApiResponse,
      UpdateProfileSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sites/${queryArg.siteId}/site_settings/profile_settings`,
        method: "PATCH",
        body: queryArg.updateProfileSettingsReqBodyDto,
      }),
    }),
    updateBetaCenterSettings: build.mutation<
      UpdateBetaCenterSettingsApiResponse,
      UpdateBetaCenterSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sites/${queryArg.siteId}/site_settings/beta_center_settings/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.updateBetaCenterSettingsReqBodyDto,
      }),
    }),
    getPayee: build.query<GetPayeeApiResponse, GetPayeeApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sites/${queryArg.siteId}/site_settings/payees`,
      }),
    }),
    createPayee: build.mutation<CreatePayeeApiResponse, CreatePayeeApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sites/${queryArg.siteId}/site_settings/payees`,
        method: "POST",
        body: queryArg.createPayeeBodyDto,
      }),
    }),
    getExistingPayees: build.query<
      GetExistingPayeesApiResponse,
      GetExistingPayeesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sites/${queryArg.siteId}/site_settings/payees/existing_payees`,
      }),
    }),
    confirmPayee: build.mutation<ConfirmPayeeApiResponse, ConfirmPayeeApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sites/${queryArg.siteId}/site_settings/payees/confirm`,
        method: "PATCH",
      }),
    }),
    choosePayee: build.mutation<ChoosePayeeApiResponse, ChoosePayeeApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sites/${queryArg.siteId}/site_settings/payees/choose`,
        method: "PATCH",
        body: queryArg.choosePayeeBodyDto,
      }),
    }),
    videoCallback: build.mutation<
      VideoCallbackApiResponse,
      VideoCallbackApiArg
    >({
      query: (queryArg) => ({
        url: `/videos/callback`,
        method: "POST",
        body: queryArg.createVideoCbReqBodyDto,
        headers: {
          "x-cld-timestamp": queryArg["x-cld-timestamp"],
          "x-cld-signature": queryArg["x-cld-signature"],
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as dashboardApi };
export type GetUserApiResponse = unknown;
export type GetUserApiArg = {
  id: number;
};
export type ListCountriesApiResponse =
  /** status 200  */ ListCountriesResponseDto;
export type ListCountriesApiArg = {
  /** Filter to a single country by name (exact match) */
  name?: string;
  /** Filter to a single country by code (exact match) */
  code?: string;
};
export type GetCountryApiResponse = /** status 200  */ GetCountryResponseDto;
export type GetCountryApiArg = {
  /** Country id */
  id: number;
};
export type SignInApiResponse = /** status 201  */ ResSessionsSignInDto;
export type SignInApiArg = {
  reqSessionsSignInDto: ReqSessionsSignInDto;
};
export type ListUserFeaturesApiResponse =
  /** status 200  */ ListFeaturesResponseDto;
export type ListUserFeaturesApiArg = {
  /** Filter features by user id */
  userId: number;
};
export type ListSitesApiResponse = /** status 200  */ ListSitesResponseDto;
export type ListSitesApiArg = {
  /** Field to sort results by. Admins can only sort by title and live_on. */
  sort?: "title" | "live_on" | "pageviews" | "revenue" | "rpm";
  /** Direction to sort results */
  direction?: "asc" | "desc";
  /** start_date for report totals */
  startDate?: string;
  /** end_date for report totals */
  endDate?: string;
  /** Pagination page */
  page?: number;
  /** Number of results per page */
  perPage?: number;
  /** Only used by a past version of MCP. */
  slug?: string;
  /** Filter results to list of site_ids */
  siteIds?: number[];
  /** Filter sites based on site titles and slugs */
  query?: string;
};
export type GetSiteApiResponse = /** status 200  */ GetSiteResponseDto;
export type GetSiteApiArg = {
  /** The site id */
  id: number;
};
export type ListSitesLightApiResponse = unknown;
export type ListSitesLightApiArg = void;
export type GenerateSignatureApiResponse =
  /** status 200  */ GenerateSignatureResponseDto;
export type GenerateSignatureApiArg = {
  /** The site id */
  siteId: number;
  resourceType?: string;
};
export type ListOptoutsApiResponse = /** status 200  */ ListOptoutsResponseDto;
export type ListOptoutsApiArg = void;
export type ListCategoriesApiResponse =
  /** status 200  */ ListCategoriesResponseDto;
export type ListCategoriesApiArg = void;
export type ListPsAsApiResponse = /** status 200  */ ListPsasResponseDto;
export type ListPsAsApiArg = void;
export type ListSiteUsersApiResponse =
  /** status 200  */ ListSiteUsersResponseDto;
export type ListSiteUsersApiArg = {
  /** The site id */
  siteId: number;
};
export type CreateSiteUserApiResponse =
  /** status 201  */ CreateSiteUserResponseDto;
export type CreateSiteUserApiArg = {
  /** The site id */
  siteId: number;
  createSiteUserBodyDto: CreateSiteUserBodyDto;
};
export type UpdateSiteUserApiResponse =
  /** status 200  */ UpdateSiteUserResponseDto;
export type UpdateSiteUserApiArg = {
  siteId: number;
  siteUserId: number;
  updateSiteUserBodyDto: UpdateSiteUserBodyDto;
};
export type DeleteSiteUserApiResponse = unknown;
export type DeleteSiteUserApiArg = {
  siteId: number;
  siteUserId: number;
};
export type GetReportsMetricsSummaryApiResponse = unknown;
export type GetReportsMetricsSummaryApiArg = void;
export type ListPlaylistsApiResponse =
  /** status 200  */ ListPlaylistsResponseDto;
export type ListPlaylistsApiArg = {
  siteId: number;
  /** Pagination results per page */
  perPage?: number;
  /** String to search playlist titles */
  query?: string;
  /** Pagination value */
  page?: number;
};
export type CreatePlaylistApiResponse =
  /** status 201  */ CreatePlaylistResponseDto;
export type CreatePlaylistApiArg = {
  siteId: number;
  createPlaylistDto: CreatePlaylistDto;
};
export type GetPlaylistApiResponse = /** status 200  */ GetPlaylistResponseDto;
export type GetPlaylistApiArg = {
  id: number;
  siteId: number;
};
export type UpdatePlaylistApiResponse = unknown;
export type UpdatePlaylistApiArg = {
  siteId: number;
  id: number;
};
export type DeletePlaylistApiResponse = unknown;
export type DeletePlaylistApiArg = {
  siteId: number;
  id: number;
};
export type ListVideosApiResponse = /** status 200  */ GetVideosResponseDto;
export type ListVideosApiArg = {
  /** The site id */
  siteId: number;
  sort?: "title" | "duration" | "created_at" | "up_next_order";
  sortOrder: "asc" | "desc";
  query?: string;
  page: number;
  perPage: number;
  priority?: string | null;
};
export type CreateVideoApiResponse =
  /** status 201  */ ResCreateVideoWrapperDto;
export type CreateVideoApiArg = {
  siteId: number;
  reqCreateVideoDto: ReqCreateVideoDto;
};
export type GetVideoApiResponse = unknown;
export type GetVideoApiArg = {
  slug: string;
  siteId: number;
};
export type ReprocessVideoApiResponse =
  /** status 200  */ ResCreateVideoWrapperDto;
export type ReprocessVideoApiArg = {
  siteId: number;
  slug: string;
};
export type GetMcmChildPublisherApiResponse =
  /** status 200  */ GetMcmChildPublisherResponseDto;
export type GetMcmChildPublisherApiArg = {
  /** The MCM Child Publisher id */
  id: number;
};
export type GetMcmGamSiteApiResponse =
  /** status 200  */ GetMcmGamSiteResponseDto;
export type GetMcmGamSiteApiArg = {
  /** The MCM GAM Site ID */
  id: number;
};
export type GetProfileSettingsApiResponse =
  /** status 200  */ GetProfileSettingsResponseDto;
export type GetProfileSettingsApiArg = {
  siteId: number;
};
export type UpdateProfileSettingsApiResponse =
  /** status 200  */ GetProfileSettingsResponseDto;
export type UpdateProfileSettingsApiArg = {
  siteId: number;
  updateProfileSettingsReqBodyDto: UpdateProfileSettingsReqBodyDto;
};
export type UpdateBetaCenterSettingsApiResponse =
  /** status 200  */ UpdateBetaCenterSettingsResponseDto;
export type UpdateBetaCenterSettingsApiArg = {
  siteId: number;
  id: number;
  updateBetaCenterSettingsReqBodyDto: UpdateBetaCenterSettingsReqBodyDto;
};
export type GetPayeeApiResponse = /** status 200  */ GetPayeeResponseDto;
export type GetPayeeApiArg = {
  /** The site id */
  siteId: number;
};
export type CreatePayeeApiResponse = /** status 201  */ GetPayeeResponseDto;
export type CreatePayeeApiArg = {
  /** The site id to associate the newly created payee with */
  siteId: number;
  createPayeeBodyDto: CreatePayeeBodyDto;
};
export type GetExistingPayeesApiResponse =
  /** status 200  */ GetExistingPayeesResponseDto;
export type GetExistingPayeesApiArg = {
  /** The site id */
  siteId: number;
};
export type ConfirmPayeeApiResponse = /** status 200  */ GetPayeeResponseDto;
export type ConfirmPayeeApiArg = {
  /** The site id to associate the newly created payee with */
  siteId: number;
};
export type ChoosePayeeApiResponse = /** status 200  */ GetPayeeResponseDto;
export type ChoosePayeeApiArg = {
  /** The site id to associate the existing payee with */
  siteId: number;
  choosePayeeBodyDto: ChoosePayeeBodyDto;
};
export type VideoCallbackApiResponse = /** status 200  */ string;
export type VideoCallbackApiArg = {
  /** Timestamp of when signature was generated. Ensures signature is unique. */
  "x-cld-timestamp": string;
  /** Signature created with our API secret from Cloudinary. Ensures request is from Cloudinary. */
  "x-cld-signature": string;
  createVideoCbReqBodyDto: CreateVideoCbReqBodyDto;
};
export type CountryDto = {
  code: string | null;
  id: number;
  name: string | null;
};
export type ListCountriesResponseDto = {
  country: CountryDto[];
};
export type GetCountryResponseDto = {
  country: CountryDto;
};
export type GrowSitesDto = {
  site_id: number;
  grow_site_id: string;
};
export type SiteUserDto = {
  id: number;
  user_id: number | null;
  site_id: number | null;
  roles_mask: number | null;
};
export type PayeesDto = {
  id: number;
  tipalti_completed: boolean | null;
  uuid: string | null;
  name: string | null;
};
export type UserDto = {
  authentication_token: string | null;
  email: string;
  id: number;
  favorite_sites: string | null;
  read_only_admin: boolean | null;
  twilio_verify_enabled: boolean | null;
  name: string | null;
  roles: string[];
  enterprise_sites: number[];
  premiere_sites: number[];
  premiere_sites_invited: number[];
  pro_sites: number[];
  pro_sites_invited: number[];
  grow_site_ids: string[];
  grow_sites: GrowSitesDto[];
  site_users: SiteUserDto[];
  user_hash: string;
  site_ids: number[];
  super_admin: boolean | null;
  payees: PayeesDto[];
};
export type SessionsSignInDataDto = {
  token: string;
  user: UserDto;
};
export type ResSessionsSignInDto = {
  data: SessionsSignInDataDto;
};
export type ReqSessionsSignInDto = {
  email: string;
  password: string;
};
export type FeatureDto = {
  feature_name: string;
};
export type ListFeaturesResponseDto = {
  features: FeatureDto[];
};
export type SiteItemDto = {
  live_on?: string | null;
  id: number;
  needs_payment?: boolean | null;
  pageviews?: number | null;
  revenue?: number | null;
  rpm?: number | null;
  title?: string | null;
  adunit?: string | null;
  launch_mode?: boolean | null;
};
export type ReportTotalsTableDto = {
  pageviews: number | null;
  revenue: number | null;
};
export type ReportTotalsDto = {
  table: ReportTotalsTableDto;
};
export type ListSitesResponseDto = {
  sites: SiteItemDto[];
  total_count?: number;
  report_totals?: ReportTotalsDto;
};
export type SiteImagesDto = {
  screenshot?: string | null;
  site_logo?: string | null;
  avatar?: string | null;
};
export type SiteLoyaltyDto = {
  anniversary_on?: string | null;
  live_on?: string | null;
  loyalty_bonus?: number | null;
  revenue_share?: number | null;
  impressions?: number | null;
  impressions_for_eighty?: number | null;
  impressions_for_eightytwofive?: number | null;
  impressions_for_eightyfive?: number | null;
};
export type SiteDto = {
  address_verified_at?: string | null;
  pro_last_audit?: string | null;
  pro_last_inspected?: string | null;
  id: number;
  deactivated: boolean;
  title: string | null;
  slug: string | null;
  domain: string | null;
  test_site: boolean | null;
  do_not_pay: boolean | null;
  created_at: string;
  offering_id: number | null;
  opt_in_to_beta_center: boolean | null;
  killswitch?: boolean | null;
  disable_reporting?: boolean | null;
  owned?: boolean | null;
  chicory_enabled?: boolean | null;
  zergnet_enabled?: boolean | null;
  loyalty_bonus_disabled?: boolean | null;
  enable_automatic_recipe_selectors?: boolean | null;
  given_notice?: boolean | null;
  images: SiteImagesDto;
  loyalty?: SiteLoyaltyDto;
  needs_payment: boolean;
  tipalti_completed?: boolean | null;
  premiere_invited?: boolean | null;
  premiere_invited_on?: string | null;
  premiere_accepted?: boolean | null;
  premiere_accepted_on?: string | null;
  premiere_accepted_by?: string | null;
  premiere_manage_account?: boolean | null;
  accepted_terms_of_service?: boolean | null;
  accepted_terms_of_service_by?: string | null;
  accepted_terms_of_service_on?: string | null;
  address_exists?: boolean | null;
  address_expired?: boolean | null;
  features: string[];
  ganalytics_state?: string | null;
  ganalytics_refresh_token_expired_at?: string | null;
  grow_site_id?: string | null;
  pro_invited?: boolean | null;
  pro_invited_on?: string | null;
  revenue_share_pro?: number | null;
  pro_accepted?: boolean | null;
  pro_accepted_on?: string | null;
  pro_accepted_by?: string | null;
  uuid?: string | null;
  disable_onboarding_wizard?: boolean | null;
  ga4_live_on?: string | null;
  ga4_settings_page?: boolean | null;
  ga4_property_connected?: boolean | null;
  analytics_connection_status?: string | null;
  gutter_enable?: boolean | null;
};
export type GetSiteResponseDto = {
  site: SiteDto;
};
export type GenerateSignatureResponseDto = {
  image_metadata?: boolean;
  signature: string;
  source?: string;
  tags: string;
  timestamp: number;
  upload_preset?: string;
};
export type OptoutDto = {
  id: number;
  title: string | null;
  description: string | null;
  slug: string | null;
};
export type ListOptoutsResponseDto = {
  optouts: OptoutDto[];
};
export type CategoryDto = {
  id: number;
  title: string | null;
  slug: string | null;
};
export type ListCategoriesResponseDto = {
  categories: CategoryDto[];
};
export type PsasDto = {
  id: number;
  title: string | null;
  slug: string | null;
  gam_key: string | null;
  description: string | null;
};
export type ListPsasResponseDto = {
  psas: PsasDto[];
};
export type ListSiteUsersResponseDto = {
  site_users: SiteUserDto[];
};
export type CreateSiteUserResponseDto = {
  roles: (
    | "ad_settings"
    | "reporting"
    | "video"
    | "payment"
    | "owner"
    | "post_termination_new_owner"
  )[];
  id: number;
  created_at: string;
  updated_at: string;
  site_id: number | null;
  user_id: number | null;
};
export type CreateSiteUserBodyDto = {
  roles: (
    | "ad_settings"
    | "reporting"
    | "video"
    | "payment"
    | "owner"
    | "post_termination_new_owner"
  )[];
  email: string;
  email_confirmation: string;
};
export type UpdateSiteUserResponseDto = {
  roles: (
    | "ad_settings"
    | "reporting"
    | "video"
    | "payment"
    | "owner"
    | "post_termination_new_owner"
  )[];
  id: number;
  created_at: string;
  updated_at: string;
  site_id: number | null;
  user_id: number | null;
};
export type UpdateSiteUserBodyDto = {
  roles: (
    | "ad_settings"
    | "reporting"
    | "video"
    | "payment"
    | "owner"
    | "post_termination_new_owner"
  )[];
};
export type PlaylistVideoDto = {
  image?: string | null;
  slug?: string | null;
  title?: string | null;
};
export type PlaylistDto = {
  created_at: string;
  description: string | null;
  headline: string | null;
  id: number;
  image: string | null;
  site_id: number | null;
  title: string | null;
  updated_at: string;
  user_id: number | null;
  videos: PlaylistVideoDto[];
};
export type ListPlaylistsResponseDto = {
  playlists: PlaylistDto[];
  total_count: number;
};
export type CreatePlaylistResponseDto = {
  playlist: PlaylistDto;
};
export type VideoTrackDto = {
  id: number;
  video_id: number | null;
  filetype?: string | null;
  kind?: string | null;
  srclang?: string | null;
  slug?: string | null;
  label?: string | null;
  default?: boolean | null;
  created_at?: string | null;
  updated_at?: string | null;
};
export type VideoChapterDto = {
  id: number;
  video_id: number | null;
  time?: number | null;
  description?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
};
export type VideoDto = {
  status?: ("processing" | "live" | "deleted") | null;
  up_next_order?: ("standard" | "boosted" | "excluded") | null;
  id: number;
  description?: string | null;
  duration?: number | null;
  file?: string | null;
  height?: number | null;
  image?: string | null;
  keywords?: string | null;
  permalink?: string | null;
  site_id: number | null;
  slug?: string | null;
  title?: string | null;
  user_id: number | null;
  video_number?: number | null;
  vtt: VideoTrackDto[];
  video_chapters: VideoChapterDto[];
  video_headline: string | null;
  width: number | null;
  created_at?: string | null;
  updated_at?: string | null;
};
export type CreatePlaylistDto = {
  videos: VideoDto[];
  description: string;
  headline: string | null;
  image: string | null;
  title: string;
};
export type GetPlaylistResponseDto = {
  playlist: PlaylistDto;
};
export type GetVideosResponseDto = {
  videos: VideoDto[];
  total_count: number;
};
export type Meta = {
  default_video_headline?: string | null;
  default_video_headline_el?: string | null;
  player_aspect_ratio?: string | null;
  disable_overlay?: string | null;
  hoist_first_video?: string | null;
  hoist_first_video_mobile?: string | null;
  hoist_first_video_desktop?: string | null;
  featured_video_type?: string | null;
  featured_video_id?: string | null;
  auto_insert_sticky_video?: string | null;
  sticky_content_side?: string | null;
  mobile_popout_placement?: string | null;
  mobile_popout_top_margin?: string | null;
  custom_hoist_selector?: string | null;
  custom_hoist_position?: string | null;
  auto_insert_offset?: string | null;
  player_background?: string | null;
  default_vtt?: string | null;
  midroll_enabled?: string | null;
  featured_video_allow_selector?: string | null;
};
export type ResCreateVideoDto = {
  aspect_ratio: string;
  created_at: string | null;
  description: string | null;
  duration: number | null;
  file: string | null;
  has_unique_permalink: boolean;
  height: number | null;
  id: number | null;
  image: string | null;
  keywords: string | null;
  meta: Meta;
  permalink: string | null;
  site_id: number | null;
  slug: string | null;
  status: string | null;
  streaming_profile: string | null;
  title: string | null;
  up_next_order: string | null;
  updated_at: string | null;
  user_id: number | null;
  video_chapters: VideoChapterDto[];
  video_headline: string | null;
  video_number: number | null;
  vtt: VideoTrackDto[];
  width: number | null;
};
export type ResCreateVideoWrapperDto = {
  video: ResCreateVideoDto;
};
export type VideoTracksAttributes = {
  filetype: string;
  kind: string;
  label: string;
  slug: string;
  srclang: string;
};
export type VideoChaptersAttributes = {
  description: string;
  time: number;
};
export type FileJson = {
  public_id: string;
  duration: number;
  height: number;
  width: number;
  version: string;
};
export type ImageJson = {
  version: string;
  public_id: string;
};
export type ReqCreateVideoDto = {
  up_next_order: "standard" | "boosted" | "excluded";
  description: string;
  image: string;
  keywords: string;
  permalink: string;
  title: string;
  video_tracks_attributes?: VideoTracksAttributes[];
  video_chapters_attributes?: VideoChaptersAttributes[];
  video_headline: string;
  video_number: number;
  file_json: FileJson;
  image_json: ImageJson;
};
export type McmChildPublisherDto = {
  id: number;
  business_domain?: string | null;
  business_name?: string | null;
};
export type GetMcmChildPublisherResponseDto = {
  mcm_child_publisher: McmChildPublisherDto;
};
export type McmGamSiteChildPublisherDto = {
  id: number;
  status?: string | null;
};
export type McmGamSiteDto = {
  id: number;
  status?: string | null;
  mcm_child_publisher: McmGamSiteChildPublisherDto | null;
};
export type GetMcmGamSiteResponseDto = {
  mcm_gam_site: McmGamSiteDto;
};
export type ProfileSettingsCategoryDto = {
  created_at: string;
  iab_code?: string | null;
  id: number;
  parent_id?: number | null;
  slug?: string | null;
  title?: string | null;
  updated_at: string;
};
export type ProfileSettingsSiteDto = {
  address_verified_at?: string | null;
  accepted_terms_of_service?: boolean | null;
  accepted_terms_of_service_by?: string | null;
  accepted_terms_of_service_on?: string | null;
  address1?: string | null;
  address_exists?: boolean | null;
  address_expired?: boolean | null;
  author_bio?: string | null;
  author_image?: string | null;
  author_name?: string | null;
  brand_color?: string | null;
  category_id?: ProfileSettingsCategoryDto | null;
  category_ids?: ProfileSettingsCategoryDto[];
  city?: string | null;
  contact_email?: string | null;
  country?: string | null;
  country_of_operation?: string | null;
  facebook?: string | null;
  influencer_non_profit_rate?: number | null;
  influencer_non_profit_work?: boolean | null;
  instagram?: string | null;
  phone_number?: string | null;
  pinterest?: string | null;
  pinterest_email?: string | null;
  premiere_accepted?: boolean | null;
  premiere_invited?: boolean | null;
  premiere_manage_account?: boolean | null;
  premiere_net_difference?: number | null;
  pro_accepted?: boolean | null;
  pro_invited?: boolean | null;
  site_description?: string | null;
  site_id?: number;
  site_image?: string | null;
  site_title?: string | null;
  snapchat?: string | null;
  state?: string | null;
  tiktok?: string | null;
  twitter?: string | null;
  youtube?: string | null;
  zipcode?: string | null;
};
export type GetProfileSettingsResponseDto = {
  site: ProfileSettingsSiteDto;
};
export type UpdateProfileSettingsReqBodyDto = {
  site_description?: string;
  site_image?: string;
  slug_override?: string;
  author_name?: string;
  contact_email?: string;
  pinterest_email?: string;
  facebook?: string;
  snapchat?: string;
  instagram?: string;
  tiktok?: string;
  twitter?: string;
  youtube?: string;
  pinterest?: string;
  address1?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  country?: string;
  author_bio?: string;
  author_image?: string;
  screenshot_timestamp?: number;
  given_notice?: boolean;
  phone_number?: string;
  category_id?: number;
  category_ids?: number[];
  country_of_operation?: string;
  brand_color?: string;
  accepted_terms_of_service?: boolean;
  pro_accepted?: boolean | null;
  premiere_accepted?: boolean;
  premiere_manage_account?: boolean;
  verify_address?: boolean;
};
export type BetaCenterSettingsDto = {
  id: number;
  opt_in_option: string;
  opt_in_value: boolean | null;
  created_at: string;
  updated_at: string;
};
export type UpdateBetaCenterSettingsResponseDto = {
  beta_center_setting: BetaCenterSettingsDto;
};
export type UpdateBetaCenterSettingsReqBodyDto = {
  opt_in_option?: string;
  opt_in_value?: boolean | null;
};
export type PayeeFramesDto = {
  history: string | null;
  edit_profile: string | null;
};
export type PayeeDto = {
  id: number;
  name: string | null;
  tipalti_completed: boolean | null;
  uuid: string | null;
  created_at: string;
  updated_at: string;
};
export type GetPayeeResponseSiteDto = {
  frames: PayeeFramesDto;
  payee: PayeeDto | null;
  site: number;
};
export type GetPayeeResponseDto = {
  site: GetPayeeResponseSiteDto;
};
export type CreatePayeeBodyDto = {
  name: string;
};
export type ExistingPayeeDto = {
  id: number;
  name: string | null;
  tipalti_completed: boolean | null;
  uuid: string | null;
};
export type GetExistingPayeesResponseDto = {
  payees: ExistingPayeeDto[];
};
export type ChoosePayeeBodyDto = {
  payee_id: number;
};
export type CreateVideoCbReqBodyDto = {
  public_id: string;
  version: number;
  width: number;
  height: number;
  format: string;
  resource_type: string;
  created_at: string;
  bytes: number;
  notification_type: string;
  url: string;
  secure_url: string;
};
export const {
  useGetUserQuery,
  useListCountriesQuery,
  useGetCountryQuery,
  useSignInMutation,
  useListUserFeaturesQuery,
  useListSitesQuery,
  useGetSiteQuery,
  useListSitesLightQuery,
  useGenerateSignatureQuery,
  useListOptoutsQuery,
  useListCategoriesQuery,
  useListPsAsQuery,
  useListSiteUsersQuery,
  useCreateSiteUserMutation,
  useUpdateSiteUserMutation,
  useDeleteSiteUserMutation,
  useGetReportsMetricsSummaryQuery,
  useListPlaylistsQuery,
  useCreatePlaylistMutation,
  useGetPlaylistQuery,
  useUpdatePlaylistMutation,
  useDeletePlaylistMutation,
  useListVideosQuery,
  useCreateVideoMutation,
  useGetVideoQuery,
  useReprocessVideoMutation,
  useGetMcmChildPublisherQuery,
  useGetMcmGamSiteQuery,
  useGetProfileSettingsQuery,
  useUpdateProfileSettingsMutation,
  useUpdateBetaCenterSettingsMutation,
  useGetPayeeQuery,
  useCreatePayeeMutation,
  useGetExistingPayeesQuery,
  useConfirmPayeeMutation,
  useChoosePayeeMutation,
  useVideoCallbackMutation,
} = injectedRtkApi;
