import * as React from "react";
import SnowplowTracker from "./snowplowTracker";
import {
  SNOWPLOW_APP_ID,
  SNOWPLOW_TRACKER_NAMESPACE,
  REACT_APP_PACKAGE_VERSION,
} from "./constants";
import { useWidgetSessionId } from "./WidgetContext";
import { useABTestingData } from "./ABTesting";
import { Network } from "@mediavine/ui";
import { UserData } from "helpers/context";

export type ProductTourTrackingEvents =
  | {
      name: "product_tour_start";
      stepName: string;
      totalSteps: number;
    }
  | {
      name: "product_tour_previous_click";
      stepName: string;
      stepNumber: number;
      totalSteps: number;
    }
  | {
      name: "product_tour_next_click";
      stepName: string;
      stepNumber: number;
      totalSteps: number;
    }
  | {
      name: "product_tour_exit_click";
      stepName: string;
      stepNumber: number;
      totalSteps: number;
    };

type UseSendTrackingEventFnParams =
  | ProductTourTrackingEvents
  | { name: "render_route" }
  | { name: "render_route_fail" }
  | { name: "settings_update" }
  | { name: "page_scroll" }
  | { name: "login" }
  | { name: "onboarding_wizard_begin" }
  | { name: "onboarding_wizard_next_step_select_platform" }
  | { name: "onboarding_wizard_next_step_install_mcp" }
  | { name: "onboarding_wizard_next_step_install_script_tag" }
  | { name: "onboarding_wizard_next_step_connect_adsense" }
  | { name: "onboarding_wizard_next_step_check_email" }
  | { name: "onboarding_wizard_done" };

const collectorUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_SNOWPLOW_COLLECTOR_DEV_URL
    : process.env.REACT_APP_SNOWPLOW_COLLECTOR_PROD_URL;

const snowplowTracker = new SnowplowTracker({
  collectorUrl: collectorUrl || "",
  appId: SNOWPLOW_APP_ID,
  trackerNamespace: SNOWPLOW_TRACKER_NAMESPACE,
  appVersion: REACT_APP_PACKAGE_VERSION,
});

export function useSendTrackingEvent(siteId?: string) {
  const widgetSessionId = useWidgetSessionId();
  const { betaVersion, betaName } = useABTestingData();
  const {
    userData: { id: userId },
  } = React.useContext<{ userData: UserData }>(Network.JWTContext as any);

  return React.useCallback(
    ({ name }: UseSendTrackingEventFnParams) => {
      // Enable for logging during development
      //   if (process.env.NODE_ENV === 'development') {
      //     console.info(`Tracking ${name} event`)
      //   }
      return snowplowTracker.sendEvent({
        action: name,
        userId: userId?.toString(),
        siteId: siteId,
        appVersion: betaVersion,
        betaName,
        category: "pageActions",
        widgetSessionId: widgetSessionId || undefined,
      });
    },
    [widgetSessionId, betaName, betaVersion, userId, siteId]
  );
}
