import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { Button, Grid, Input, Notice, Text, Box, Flex } from "@mediavine/ui";
import FormContainer from "./FormContainer";
import AuthLayout from "components/AuthLayout";
import { useUpdate } from "helpers/hooks";
import { getApi } from "helpers/data";
import { normalizeTwoFactorRes } from "helpers/normalizeTwoFactorRes";

interface ITwoFactorFormProps {
  userId: number;
  onVerified(): void;
}

// Sends a one time passcode via SMS to a user's mobile phone
async function requestSms(userId: number) {
  const api = getApi();
  const res = await api.get(
    `/users/${userId}/twilio_verify/create_verification`
  );
  return normalizeTwoFactorRes(res);
}

export default function TwoFactorForm({
  userId,
  onVerified,
}: ITwoFactorFormProps) {
  const [smsRequestStatus, setSmsRequestStatus] = useState<boolean>(true);
  const handleReset = () => {
    localStorage.clear();
    window.location.reload();
  };
  const { execute, updateError } = useUpdate(async (data: any) => {
    const api = getApi();
    const res = await api.post(
      `/users/${data.user_id}/twilio_verify/check_verification`,
      data
    );

    return normalizeTwoFactorRes(res);
  }, {});

  // Requests an initial SMS verification code upon render
  useEffect(() => {
    const requestInitialSms = async () => {
      try {
        const verificationSuccess = await requestSms(userId);
        setSmsRequestStatus(verificationSuccess);
      } catch (e) {
        setSmsRequestStatus(false);
      }
    };
    requestInitialSms();
  }, [userId]);

  return (
    <Form
      initialValues={{ user_id: userId, code: "" }}
      onSubmit={async (data) => {
        const smsSent = await execute(data);
        if (smsSent) {
          onVerified();
        }
      }}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <AuthLayout title="Login with two-factor authentication">
            <FormContainer>
              {updateError && (
                <Notice kind="danger">
                  {/* @ts-ignore */}
                  {updateError.response?.statusText}
                </Notice>
              )}
              <Box bg="bodyBg" padding="sm" shadow="box">
                <Field name="code">
                  {(props) => (
                    <Input
                      {...props.input}
                      placeholder="Enter passcode"
                      autoFocus
                      kind="inline"
                    />
                  )}
                </Field>
              </Box>
              <Button kind="primary" type="submit">
                Finish logging in
              </Button>
              <Grid gutter="sm" justify="center">
                <Button
                  kind="link"
                  color="bodyLt"
                  onClick={async () => {
                    const verificationSuccess = await requestSms(userId);
                    setSmsRequestStatus(verificationSuccess);
                  }}
                >
                  Resend one-time passcode
                </Button>
                {smsRequestStatus && <Text color="success">Request sent</Text>}
                {!smsRequestStatus && (
                  <Text color="danger">Request could not be completed</Text>
                )}
              </Grid>
              <Flex justify="center">
                <Button kind="link" onClick={handleReset} color="danger">
                  Sign in as another user
                </Button>
              </Flex>
            </FormContainer>
          </AuthLayout>
        </form>
      )}
    </Form>
  );
}
