import React, { createContext, useContext, useEffect, useReducer } from "react";
import Axios from "axios";
import loadjs from "loadjs";
import { AnalyticsData, MediavineReport } from "./types";
import { SiteDataContext } from "state/context/site/siteCtx";
import { getJwt } from "./data";

export interface GapiView {
  currency?: string;
  id?: string;
  name?: string;
  timezone?: string;
  website_url?: string;
}

export interface GapiProperty {
  id?: string;
  name?: string;
  profiles?: GapiView[];
}
export interface GoogleAnalyticsState {
  clientInitialized: boolean;
  googleUser?: gapi.auth2.GoogleUser;
  code?: string;
  selectedProperty?: string;
  selectedProfile?: string;
  properties?: GapiProperty[];
  profiles?: GapiView[];
  accounts?: gapi.client.analytics.Account[];
  reports?: gapi.client.analyticsreporting.Report[];
  mediavineReport?: MediavineReport;
  fetchingProperties: boolean;
  fetchingProfiles: boolean;
  fetchingReports: boolean;
  fetchingAccounts: boolean;
  analyticsData?: AnalyticsData;
}

export enum GoogleAnalyticsActionType {
  SignIn,
  SignOut,
  InitializedClient,
  FetchedProperties,
  FetchedProfiles,
  SelectProperty,
  SelectProfile,
  FetchedReports,
  FetchedAccounts,
  GoogleUserSignIn,
  GoogleUserSignInCode,
  FetchingProperties,
  FetchingProfiles,
  FetchingReports,
  FetchingAccounts,
}

interface GoogleAnalyticsActionWithoutPayload {
  type:
    | GoogleAnalyticsActionType.SignOut
    | GoogleAnalyticsActionType.FetchingProperties
    | GoogleAnalyticsActionType.FetchingProfiles
    | GoogleAnalyticsActionType.FetchingReports
    | GoogleAnalyticsActionType.FetchingAccounts
    | GoogleAnalyticsActionType.InitializedClient;
}

interface GoogleAnalyticsSelectPropertyAction {
  type: GoogleAnalyticsActionType.SelectProperty;
  payload: Pick<GoogleAnalyticsState, "selectedProperty">;
}

interface GoogleAnalyticsSelectProfileAction {
  type: GoogleAnalyticsActionType.SelectProfile;
  payload: Pick<GoogleAnalyticsState, "selectedProfile">;
}

interface GoogleAnalyticsGoogleUserSignInAction {
  type: GoogleAnalyticsActionType.GoogleUserSignIn;
  payload: Pick<GoogleAnalyticsState, "googleUser">;
}

interface GoogleAnalyticsGoogleUserSignCodeInAction {
  type: GoogleAnalyticsActionType.GoogleUserSignInCode;
  payload: Pick<GoogleAnalyticsState, "code">;
}

interface GoogleAnalyticsFetchedProfilesAction {
  type: GoogleAnalyticsActionType.FetchedProfiles;
  payload: Pick<GoogleAnalyticsState, "profiles">;
}

interface GoogleAnalyticsFetchedPropertiesAction {
  type: GoogleAnalyticsActionType.FetchedProperties;
  payload: Pick<GoogleAnalyticsState, "properties">;
}

interface GoogleAnalyticsFetchedAccountsAction {
  type: GoogleAnalyticsActionType.FetchedAccounts;
  payload: Pick<GoogleAnalyticsState, "accounts">;
}

interface GoogleAnalyticsFetchedReportsAction {
  type: GoogleAnalyticsActionType.FetchedReports;
  payload: Pick<GoogleAnalyticsState, "mediavineReport" | "reports">;
}

type GoogleAnalyticsAction =
  | GoogleAnalyticsActionWithoutPayload
  | GoogleAnalyticsFetchedReportsAction
  | GoogleAnalyticsFetchedPropertiesAction
  | GoogleAnalyticsFetchedProfilesAction
  | GoogleAnalyticsSelectPropertyAction
  | GoogleAnalyticsSelectProfileAction
  | GoogleAnalyticsFetchedAccountsAction
  | GoogleAnalyticsGoogleUserSignInAction
  | GoogleAnalyticsGoogleUserSignCodeInAction;

function googleAnalyticsReducer(
  state: GoogleAnalyticsState,
  action: GoogleAnalyticsAction
): GoogleAnalyticsState {
  switch (action.type) {
    case GoogleAnalyticsActionType.FetchingReports:
      return {
        ...state,
        fetchingReports: true,
      };
    case GoogleAnalyticsActionType.FetchingProperties:
      return {
        ...state,
        fetchingProperties: true,
      };
    case GoogleAnalyticsActionType.FetchingProfiles:
      return {
        ...state,
        fetchingProfiles: true,
      };
    case GoogleAnalyticsActionType.FetchingAccounts:
      return {
        ...state,
        fetchingAccounts: true,
      };
    case GoogleAnalyticsActionType.GoogleUserSignIn:
      return {
        ...state,
        googleUser: action.payload.googleUser,
      };
    case GoogleAnalyticsActionType.GoogleUserSignInCode:
      return {
        ...state,
        code: action.payload.code,
      };
    case GoogleAnalyticsActionType.SelectProperty:
      return {
        ...state,
        selectedProperty: action.payload.selectedProperty,
        mediavineReport: undefined,
      };
    case GoogleAnalyticsActionType.SelectProfile:
      return {
        ...state,
        selectedProfile: action.payload.selectedProfile,
        mediavineReport: undefined,
      };
    case GoogleAnalyticsActionType.SignOut:
      return {
        clientInitialized: state.clientInitialized,
        fetchingProperties: false,
        fetchingProfiles: false,
        fetchingReports: false,
        fetchingAccounts: false,
      };
    case GoogleAnalyticsActionType.InitializedClient:
      return {
        ...state,
        clientInitialized: true,
      };
    case GoogleAnalyticsActionType.FetchedReports:
      return {
        ...state,
        fetchingReports: false,
        mediavineReport: action.payload.mediavineReport,
      };
    case GoogleAnalyticsActionType.FetchedProperties:
      return {
        ...state,
        fetchingProperties: false,
        properties: action.payload.properties,
      };
    case GoogleAnalyticsActionType.FetchedProfiles:
      return {
        ...state,
        fetchingProfiles: false,
        profiles: action.payload.profiles,
      };
    case GoogleAnalyticsActionType.FetchedAccounts:
      return {
        ...state,
        fetchingAccounts: false,
        accounts: action.payload.accounts,
      };
    default:
      return state;
  }
}

const INITIAL_STATE: GoogleAnalyticsContextShape = {
  clientInitialized: false,
  fetchingProperties: false,
  fetchingProfiles: false,
  fetchingReports: false,
  fetchingAccounts: false,
  setProperties: () => {},
  selectProperty: () => {},
  selectProfile: () => {},
  reset: () => {},
  signOut: () => {},
  signIn: () => {},
  grantOfflineAccess: () => {},
};

type GoogleAnalyticsContextShape = GoogleAnalyticsState & {
  setProperties: (properties: any) => void;
  selectProperty: (propertyId: string) => void;
  selectProfile: (profileId: string) => void;
  reset: () => void;
  signOut: () => void;
  signIn: () => any;
  grantOfflineAccess: () => any;
};

const GoogleAnalyticsContext =
  createContext<GoogleAnalyticsContextShape>(INITIAL_STATE);

interface GoogleAnalyticsProviderProps {
  children: React.ReactNode;
}

export function GoogleAnalyticsProvider(props: GoogleAnalyticsProviderProps) {
  const [state, dispatch] = useReducer(googleAnalyticsReducer, INITIAL_STATE);
  const { id: siteId } = React.useContext(SiteDataContext);
  const { properties, selectedProperty } = state;

  useEffect(() => {
    let isUnmounted = false;
    async function init() {
      await loadjs("https://apis.google.com/js/api.js", {
        returnPromise: true,
      });

      await new Promise((resolve) => {
        gapi.load("client:auth2:analytics:signin2", resolve);
      });

      if (
        !process.env.REACT_APP_GOOGLE_API_KEY ||
        !process.env.REACT_APP_GOOGLE_API_CLIENT_ID ||
        !process.env.REACT_APP_ANALYTICS_SCOPE
      ) {
        return;
      }

      await gapi.client
        .init({
          apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
          discoveryDocs: [
            "https://analyticsreporting.googleapis.com/$discovery/rest?version=v4",
          ],
          clientId: process.env.REACT_APP_GOOGLE_API_CLIENT_ID,
          scope: process.env.REACT_APP_ANALYTICS_SCOPE,
        })
        .then(() => {
          if (!isUnmounted) {
            dispatch({
              type: GoogleAnalyticsActionType.InitializedClient,
            });
          }
        });
    }
    init();
    return () => {
      isUnmounted = true;
    };
  }, []);

  useEffect(() => {
    properties &&
      properties.forEach((property) => {
        if (property.id === selectedProperty) {
          dispatch({
            type: GoogleAnalyticsActionType.FetchedProfiles,
            payload: {
              profiles: property.profiles,
            },
          });
        }
      });
  }, [properties, selectedProperty]);

  function setProperties(properties: any) {
    dispatch({
      type: GoogleAnalyticsActionType.FetchedProperties,
      payload: { properties: properties.data.web_properties },
    });

    // If we have an account with no profiles then don't select anything
    if (properties.data.web_properties.length > 0) {
      selectProperty(properties.data.web_properties[0].id);
    }
  }

  function selectProperty(propertyId: string) {
    dispatch({
      type: GoogleAnalyticsActionType.SelectProperty,
      payload: {
        selectedProperty: propertyId,
      },
    });
  }

  function selectProfile(profileId: string | undefined) {
    dispatch({
      type: GoogleAnalyticsActionType.SelectProfile,
      payload: {
        selectedProfile: profileId,
      },
    });
  }

  function reset() {
    // resets profiles
    dispatch({
      type: GoogleAnalyticsActionType.SelectProfile,
      payload: {
        selectedProfile: undefined,
      },
    });

    // resets selected properties
    dispatch({
      type: GoogleAnalyticsActionType.SelectProperty,
      payload: {
        selectedProperty: undefined,
      },
    });

    // resets properties
    dispatch({
      type: GoogleAnalyticsActionType.FetchedProperties,
      payload: {
        properties: undefined,
      },
    });
  }

  function signOut() {
    gapi.auth2.getAuthInstance().then((authInstance) => {
      authInstance.signOut();
    });
  }

  function signIn() {
    return gapi.auth2.getAuthInstance().then((authInstance) => {
      return authInstance
        .signIn({
          fetch_basic_profile: true,
          prompt: "select_account consent",
          scope: process.env.REACT_APP_ANALYTICS_SCOPE,
          ux_mode: "popup",
        })
        .then(
          (googleUser) => {
            dispatch({
              type: GoogleAnalyticsActionType.GoogleUserSignIn,
              payload: {
                googleUser,
              },
            });
          },
          (error) => {
            console.error(error);
          }
        );
    });
  }

  function grantOfflineAccess() {
    const token = getJwt();
    return gapi.auth2.getAuthInstance().then((authInstance) => {
      const instance = Axios.create({
        baseURL: process.env.REACT_APP_API,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return authInstance
        .grantOfflineAccess({
          prompt: "consent",
          scope: process.env.REACT_APP_ANALYTICS_SCOPE,
        })
        .then(
          async (response: any) => {
            dispatch({
              type: GoogleAnalyticsActionType.GoogleUserSignInCode,
              payload: {
                code: response.code,
              },
            });

            try {
              const responseAT: any = await instance.post(
                `/sites/${siteId}/google_access_tokens/`,
                {
                  code: response.code,
                }
              );
              return { id: responseAT.data.id };
            } catch (e) {
              console.warn(e);
            }
          },
          (error) => {
            console.error(error);
          }
        )
        .then(async (res: any) => {
          if (!res) {
            return;
          }

          try {
            const resultProperties = await instance.get(
              `/google_access_tokens/${res.id}/web_properties/`
            );
            dispatch({
              type: GoogleAnalyticsActionType.FetchedProperties,
              payload: { properties: resultProperties.data.web_properties },
            });

            // If we have an account with no profiles then don't select anything
            if (resultProperties.data.web_properties.length > 0) {
              selectProperty(resultProperties.data.web_properties[0].id);
            }

            return resultProperties;
          } catch (e) {
            console.warn(e);
          }
        });
    });
  }

  const value: GoogleAnalyticsContextShape = {
    ...state,
    setProperties,
    selectProperty,
    selectProfile,
    reset,
    signOut,
    signIn,
    grantOfflineAccess,
  };

  return (
    <GoogleAnalyticsContext.Provider value={value}>
      {props.children}
    </GoogleAnalyticsContext.Provider>
  );
}

export default function useGoogleAnalytics() {
  return useContext(GoogleAnalyticsContext);
}
