import React from "react";
import { Helmet } from "react-helmet";
import { SiteDataContext } from "state/context/site/siteCtx";

interface TitleProps {
  routeTitle?: string;
}

export default function Header({ routeTitle }: TitleProps) {
  const { title } = React.useContext(SiteDataContext);
  const final = `${routeTitle}${title ? ` | ${title}` : ""}`;
  return (
    <Helmet>
      <title>{final}</title>
    </Helmet>
  );
}
