import React from "react";
import ExternalLink from "components/ExternalLink";
import { Link } from "react-router-dom";

export const BREAKPOINT = 1160;
export const GUTTER = "50px";

// These are for the teal goals. The goals coming from the API is for the green goals and that's what we use for getting the status color
export const DESKTOP_GOAL = 4;
export const MOBILE_GOAL = 8;
export const STICKY_GOAL = 2;
export const DESKTOP_VIEWABILITY_GOAL = 65;
export const MOBILE_VIEWABILITY_GOAL = 60;

export const RESOURCE_LINK =
  "https://help.mediavine.com/en/articles/79479-optimizing-content-for-better-seo-and-ad-performance";

export const RESOURCE_LINK_VIEWABILITY =
  "https://help.mediavine.com/en/articles/5554727-viewability";

export interface IMsgSet {
  danger: string | React.ReactNode;
  warning: string | React.ReactNode;
  primary: string | React.ReactNode;
  primaryLt?: string | React.ReactNode;
  success?: string | React.ReactNode;
  bodyLt?: "";
  info: string;
  lead?: string;
}

export const PRIVACY_POLICY_HEADINGS = {
  danger: "URGENT! Please correct ASAP!",
  warning: "Needs attention!",
  success: "Missing link on homepage!",
  bodyLt: "",
  primary: "Great!",
  primaryLt: "Great!",
};

export const HEADINGS = {
  danger: "Attention!",
  warning: "Heads up!",
  success: "Almost there!",
  bodyLt: "",
  primary: "You did it!",
  primaryLt: "You did it!",
};

export const ADS_TXT_MSGS: IMsgSet = {
  danger: (
    <>
      Your site’s ads.txt file is out of date. Achieve teal star status by
      keeping this file updated on your site.{" "}
      <ExternalLink href="https://help.mediavine.com/mediavine-ads/setting-up-your-adstxt-file">
        Learn more
      </ExternalLink>{" "}
      about your ads.txt file.
    </>
  ),
  warning: (
    <>
      Your site’s ads.txt file is out of date. Achieve teal star status by
      keeping this file updated on your site.{" "}
      <ExternalLink href="https://help.mediavine.com/mediavine-ads/setting-up-your-adstxt-file">
        Learn more
      </ExternalLink>{" "}
      about your ads.txt file.
    </>
  ),
  primary: (
    <>
      Your site’s ads.txt file has achieved teal star status! Stay teal by
      keeping this file updated on your site.{" "}
      <ExternalLink href="https://help.mediavine.com/mediavine-ads/setting-up-your-adstxt-file">
        Learn more
      </ExternalLink>{" "}
      about this.
    </>
  ),
  info: "This is a file hosted on your root domain containing a list of authorized sellers of your advertising inventory.",
  bodyLt: "",
};

export const DESKTOP_AD_MSGS: IMsgSet = {
  danger: (
    <>
      Your site’s desktop in-content ads need improvement. Achieve teal star
      status by maintaining a score of {DESKTOP_GOAL.toFixed(1)} or higher.
    </>
  ),
  warning: (
    <>
      Your site’s desktop in-content ads need improvement. Achieve teal star
      status by maintaining a score of {DESKTOP_GOAL.toFixed(1)} or higher.
    </>
  ),
  success: (
    <>
      Your site’s desktop in-content ads need improvement. Achieve teal star
      status by maintaining a score of {DESKTOP_GOAL.toFixed(1)} or higher.
    </>
  ),
  primary: (
    <>
      Your site’s desktop in-content ads have achieved teal star status. Stay
      teal by maintaining a score of {DESKTOP_GOAL.toFixed(1)} or higher.
    </>
  ),
  info: "These are the in-content ad units that run inside of your site's content on desktop.",
  bodyLt: "",
};

export const MOBILE_AD_MSGS: IMsgSet = {
  danger: (
    <>
      Your site’s mobile in-content ads need improvement. Achieve teal star
      status by maintaining a score of {MOBILE_GOAL.toFixed(1)} or higher.
    </>
  ),
  warning: (
    <>
      Your site’s mobile in-content ads need improvement. Achieve teal star
      status by maintaining a score of {MOBILE_GOAL.toFixed(1)} or higher.
    </>
  ),
  success: (
    <>
      Your site’s mobile in-content ads need improvement. Achieve teal star
      status by maintaining a score of {MOBILE_GOAL.toFixed(1)} or higher.
    </>
  ),
  primary: (
    <>
      Your site’s mobile in-content ads have achieved teal star status. Stay
      teal by maintaining a score of {MOBILE_GOAL.toFixed(1)} or higher.
    </>
  ),
  info: "These are the in-content ad units that run inside of your site's content on a mobile device.",
  bodyLt: "",
};

// Teal =
// Green = Your Privacy Policy needs to be updated. Please include the text on this page in order to meet ad partner requirements. We update this periodically as more partners are added, or existing partners add this requirement. More info here

// Yellow = Your Privacy Policy needs to be updated to meet Mediavine ad partner requirements! Please add the text from this page to your site's existing privacy policy to ensure information about your ads is disclosed to your readers. More info here

// Red = Mediavine requires a Privacy Policy to be published on your site that includes text from this page, and for a link to be placed in your Dashboard Settings Privacy page. Please do this at your earliest convenience, as many ad partners require this in order to bid on your inventory. More info here

export const PP_LINK_HEALTH_CHECK =
  "https://help.mediavine.com/en/articles/4047984-privacy-policy-health-check";
const PP_LINK_HELP =
  "https://help.mediavine.com/en/articles/1867899-privacy-policy-help";
const PP_LINK_LANGUAGE =
  "https://help.mediavine.com/en/articles/3705600-privacy-policy-language";

export const PRIVACY_POLICY_MSGS: IMsgSet = {
  danger: (
    <>
      Mediavine requires a{" "}
      <a href={PP_LINK_HELP} rel="noopener noreferrer" target="_blank">
        Privacy Policy to be published on your site
      </a>{" "}
      that includes{" "}
      <a href={PP_LINK_LANGUAGE} rel="noopener noreferrer" target="_blank">
        text from this page
      </a>
      , and for a link to be placed in your{" "}
      <Link to={`../settings/privacy`}>Dashboard Settings Privacy page</Link>.
      Please do this at your earliest convenience, as many ad partners require
      this in order to bid on your inventory.
    </>
  ),
  warning: (
    <>
      Your Privacy Policy needs to be updated to meet Mediavine ad partner
      requirements! Please add the{" "}
      <a href={PP_LINK_LANGUAGE} rel="noopener noreferrer" target="_blank">
        text from this page
      </a>{" "}
      to your site's existing privacy policy to ensure information about your
      ads is disclosed to your readers, and also make sure there is a link to
      your Privacy Policy on your site's homepage, and it is the same link that
      is in your{" "}
      <Link to={`../settings/privacy`}>Mediavine Privacy Settings Page.</Link>
    </>
  ),
  success: (
    <>
      Please include a link to your Privacy Policy on your homepage. If you have
      a link there already, please check to make sure it is functional and is
      the same link included in your{" "}
      <Link to={`../settings/privacy`}>Dashboard Settings Privacy page</Link>
    </>
  ),
  primary: (
    <>
      Thanks for keeping your{" "}
      <a href={PP_LINK_HELP} rel="noopener noreferrer" target="_blank">
        Privacy Policy
      </a>{" "}
      up-to-date as it pertains to your Mediavine advertising!
    </>
  ),
  info: "This is a page on your site that discloses what information your site gathers about your readers and other legally required information.",
  bodyLt: "",
};

export const STICKY_SIDEBAR_ADS_MSGS: IMsgSet = {
  danger: (
    <>
      Your site’s sticky sidebar ad needs improvement. Achieve teal star status
      by maintaining a score of {STICKY_GOAL.toFixed(1)} or higher.
    </>
  ),
  warning: (
    <>
      Your site’s sticky sidebar ad needs improvement. Achieve teal star status
      by maintaining a score of {STICKY_GOAL.toFixed(1)} or higher.
    </>
  ),
  success: (
    <>
      Your site’s sticky sidebar ad needs improvement. Achieve teal star status
      by maintaining a score of {STICKY_GOAL.toFixed(1)} or higher.
    </>
  ),
  primary: (
    <>
      Your site’s sticky sidebar ad has achieved teal star status. Stay teal by
      maintaining a score of {STICKY_GOAL.toFixed(1)} or higher.
    </>
  ),
  info: "This is a sticky ad on the sidebar of your website and is the best performing unit across Mediavine.",
  bodyLt: "",
};

export const DESKTOP_VIEWABILITY_MSGS: IMsgSet = {
  danger: (
    <>
      Your site’s desktop content viewability ads need improvement. Achieve teal
      star status by maintaining a score of {DESKTOP_VIEWABILITY_GOAL}% or
      higher.
    </>
  ),
  warning: (
    <>
      Your site’s desktop content viewability ads need improvement. Achieve teal
      star status by maintaining a score of {DESKTOP_VIEWABILITY_GOAL}% or
      higher.
    </>
  ),
  success: (
    <>
      Your site’s desktop content viewability ads need improvement. Achieve teal
      star status by maintaining a score of {DESKTOP_VIEWABILITY_GOAL}% or
      higher.
    </>
  ),
  primary: (
    <>
      Your site’s desktop content viewability have achieved teal star status.
      Stay teal by maintaining a score of {DESKTOP_VIEWABILITY_GOAL}% or higher.
    </>
  ),
  info: "This tells us what percentage of your ads on desktop are considered viewable. Higher viewability typically means higher CPM.",
  lead: "Content Viewability scores will update daily at 10am EST.",
  bodyLt: "",
};

export const MOBILE_VIEWABILITY_MSGS: IMsgSet = {
  danger: (
    <>
      Your site’s mobile content viewability ads need improvement. Achieve teal
      star status by maintaining a score of {MOBILE_VIEWABILITY_GOAL}% or
      higher.
    </>
  ),
  warning: (
    <>
      Your site’s mobile content viewability ads need improvement. Achieve teal
      star status by maintaining a score of {MOBILE_VIEWABILITY_GOAL}% or
      higher.
    </>
  ),
  success: (
    <>
      Your site’s mobile content viewability ads need improvement. Achieve teal
      star status by maintaining a score of {MOBILE_VIEWABILITY_GOAL}% or
      higher.
    </>
  ),
  primary: (
    <>
      Your site’s mobile content viewability have achieved teal star status.
      Stay teal by maintaining a score of {MOBILE_VIEWABILITY_GOAL}% or higher.
    </>
  ),
  info: "This tells us what percentage of your ads on mobile are considered viewable. Higher viewability typically means higher CPM.",
  lead: "Content Viewability scores will update daily at 10am EST.",
  bodyLt: "",
};
