import React from "react";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import {
  Badge,
  Box,
  Button,
  CompoundBox,
  confirm,
  Flex,
  Grid,
  H4,
  H2,
  Icon,
  Input,
  Label,
  Modal,
  Notice,
  ScreenReader,
  Spinner,
  Text,
  ToggleBox,
  Truncate,
  Network,
} from "@mediavine/ui";
import { IHasUUID, assignUUIDToItems, stripUUID } from "helpers/data";
import SettingsFooter from "components/SettingsFooter";
import { SiteUser } from "helpers/context";
import { useUpdate } from "helpers/hooks";
import { useParams } from "react-router-dom";
import { getApi } from "helpers/data";
import { useListSiteUsersQuery } from "store/dashboardApi";

type UserRole =
  | "owner"
  | "ad_settings"
  | "reporting"
  | "video"
  | "payment"
  | "admin";

export type Role =
  | "owner"
  | "ad_settings"
  | "reporting"
  | "video"
  | "payment"
  | "admin"
  | "post_termination_new_owner";

export const userRoles: Array<Role> = [
  "owner",
  "ad_settings",
  "reporting",
  "video",
  "payment",
  "admin",
  "post_termination_new_owner",
];

export const roles: Array<UserRole> = [
  "ad_settings",
  "reporting",
  "video",
  "payment",
  "owner",
];

export interface IUser {
  id: string;
  user_id: string;
  site_user_id: string;
  uuid: string;
  userId: number;
  email: string;
  newUser?: boolean;
  roles: Array<UserRole>;
  _destroy?: boolean;
}

const ROLE_LABELS: { [key in UserRole]: string } = {
  ad_settings: "Ad Settings",
  reporting: "Reporting",
  video: "Video",
  payment: "Payments",
  owner: "Owner",

  // This role is not used in the UI
  admin: "null",
};
const ROLE_DESCRIPTIONS = {
  ad_settings:
    "Grants access to global settings that impact your ad implementation and can impact your revenue. (Ad settings, in-content ad settings, video settings, opt-out settings, privacy settings).",
  reporting:
    "Grants access to your dashboard's front page that details your earnings, historical earnings data, ad unit breakdown, and information about your traffic.",
  video:
    "Grants access to upload videos and update video information. Does not give access to delete videos or to change video settings. Only owners can delete video.",
  payment:
    "Grants access to your payment profiles. Will be able to edit payment info (including bank and tax info, if applicable), view payment history, make changes to payment info, etc.",
  owner:
    "Warning: Grants an all-access pass to your entire dashboard and settings, including the ability to add, edit, or remove other users (including yourself).",

  // This role is not used in the UI
  admin: "null",
};

interface IUserHeadingProps {
  onUpdate(delta: any): void;
}

function validationFns({ users }: { users: IUser[] }) {
  const err: any = {};
  if (users?.some(({ email }) => !email)) {
    err.users = "All users must have an email";
  }
  if (users?.some(({ roles }) => !roles.length)) {
    err.users = "All users must have at least one role";
  }
  return err;
}

interface AddUserModalProps {
  siteId: string;
  onAdd: (user: IUser) => void;
  onClose: () => void;
}

interface RolesControlProps {
  uuid?: string;
  value: Role[];
  onChange: (roles: Role[]) => void;
}

const RolesControls = ({
  uuid = "new-user",
  value,
  onChange,
}: RolesControlProps) => (
  <Grid gutter="sm">
    {roles.map((role: UserRole) => (
      <Box bg="bodyBg" border="border" padding="0" key={role}>
        <Flex>
          <Box bg="none" padding="sm" flex={0}>
            <Input
              id={`user-${uuid}-${role}`}
              kind="checkbox"
              value={value.includes(role)}
              onChange={() =>
                onChange(
                  value.includes(role)
                    ? value.filter((r) => r !== role)
                    : [...value, role]
                )
              }
            />
          </Box>
          <Box
            bg="none"
            border="border"
            padding="sm md"
            borders={["left"]}
            borderRadius={false}
          >
            <Grid gutter="xs">
              <Label htmlFor={`user-${uuid}-${role}`} kind="bold">
                {ROLE_LABELS[role]}
              </Label>
              <Text lineHeight="tight">{ROLE_DESCRIPTIONS[role]}</Text>
            </Grid>
          </Box>
        </Flex>
      </Box>
    ))}
  </Grid>
);

function userInviteValidation({
  email,
  roles = [],
}: {
  email: string;
  roles: Role[];
}) {
  if (!/.+?@.+?\..+/.test(email)) {
    return {
      email: "Enter an email",
    };
  }
  if (!roles.length) {
    return {
      roles: "Select at least one role",
    };
  }
  return {};
}

const AddUserModal = ({ siteId, onAdd, onClose }: AddUserModalProps) => {
  const [addedCount, setAddedCount] = React.useState(0);

  const { execute, updateError, updateStatus, reset }: any = useUpdate(
    async (data: any) => {
      const api = getApi();

      const res = await api.post(`sites/${siteId}/site_users`, {
        ...data,
        site_id: siteId,
        email_confirmation: data.email,
      });

      return res;
    },
    {}
  );

  return (
    <Form
      key={addedCount}
      initialValues={{
        email: "",
      }}
      validate={userInviteValidation}
      onSubmit={async (user) => {
        const res = await execute(user);
        // @ts-ignore
        onAdd({ ...user, site_user_id: res.data.id });
      }}
      render={({ handleSubmit, invalid, pristine }) => (
        <form onChange={() => reset(["create"])}>
          <Box>
            <Grid>
              <H4 id="add-user-heading" as="h2">
                Add New User
              </H4>
              {updateStatus === "fetched" && (
                <Box bg="none" padding="0 0 sm">
                  <Notice kind="success">
                    Invite sent!{" "}
                    <Button
                      kind="link"
                      color="success"
                      onClick={() => setAddedCount(addedCount + 1)}
                      autoFocus
                    >
                      Add another?
                    </Button>
                  </Notice>
                </Box>
              )}
              {updateError?.response?.data?.email && (
                <Box bg="none" padding="0 0 sm">
                  <Notice kind="danger">
                    {updateError.response.data.email[0]}
                  </Notice>
                </Box>
              )}
              {!updateError?.response?.data?.email &&
                updateStatus === "error" && (
                  <Box bg="none" padding="0 0 sm">
                    <Notice kind="danger">Something went wrong.</Notice>
                  </Box>
                )}
              <Box bg="none" padding="0">
                <Field
                  name="email"
                  render={(props) => (
                    <Input
                      id="email"
                      placeholder="Email"
                      value={props.input.value}
                      onChange={props.input.onChange}
                    />
                  )}
                />
              </Box>
              <Field
                name="roles"
                render={(props) => (
                  <RolesControls
                    value={props.input.value}
                    onChange={props.input.onChange}
                  />
                )}
              />
              <SettingsFooter
                status={updateStatus}
                formStatus={pristine}
                btnText="Save User"
                onSubmit={handleSubmit}
                invalid={invalid}
              />
            </Grid>
          </Box>
        </form>
      )}
    />
  );
};

const UserHeading = ({
  uuid,
  roles,
  email,
  newUser,
}: IUser & IUserHeadingProps & IHasUUID) => (
  <Flex flex={1} justify="flex-start" gutter="sm" allowChildBlowout wrap>
    <Text weight="bold" aria-labelledby={`label-${uuid}`}>
      <Truncate maxWidth="30ch">
        {email || <Text color="bodyLt">User</Text>}
      </Truncate>
    </Text>
    {newUser && <Badge color="warning">Invited</Badge>}

    <Flex flex={1} justify="flex-end" allowChildBlowout>
      <Text color="body" weight="normal">
        <Truncate maxWidth="35ch">
          {roles.map((role) => ROLE_LABELS[role]).join(", ")}
        </Truncate>
      </Text>
    </Flex>
  </Flex>
);

const Users = ({ index, fields, value, onUpdate }: any) => {
  const {
    userData: { roles, id, site_users },
    // @ts-ignore
  } = React.useContext<UserContextProps>(Network.JWTContext);

  const isUserOwnerOfSite =
    value &&
    site_users.some((site_user: SiteUser) => {
      return (
        site_user.site_id === Number(value.site_id) &&
        site_user.roles.includes("owner")
      );
    });
  const isAdmin = roles.includes("admin");
  const isMyOwnUser = value && value.user_id === id;
  return (
    <>
      {value && (
        <Box bg="none" padding="0" key={value.uuid}>
          <ToggleBox
            id={`user-${value.uuid}`}
            key={value.user_id}
            openByDefault={false}
            controls={
              <Button
                disabled={
                  (!isAdmin && isMyOwnUser) || (!isAdmin && !isUserOwnerOfSite)
                }
                kind="icon"
                onClick={async () => {
                  const m = await confirm({
                    message: `Are you sure you want to remove ${
                      value.email || "this user"
                    }?`,
                    target: document.body,
                  });
                  if (m) {
                    onUpdate(
                      fields.value.findIndex(
                        (user: IUser) =>
                          user.site_user_id === value.site_user_id
                      ),
                      { ...value, _destroy: true }
                    );
                  }
                }}
              >
                <Icon kind="trash" size="sm" color="bodyLt" />
                <ScreenReader>Remove user</ScreenReader>
              </Button>
            }
            heading={<UserHeading {...value} />}
          >
            <RolesControls
              uuid={value.uuid}
              value={value.roles}
              onChange={(roles) => onUpdate(index, { ...value, roles: roles })}
            />
          </ToggleBox>
        </Box>
      )}
    </>
  );
};

export default function UserSettings() {
  const [modal, setModal] = React.useState(false);
  const { siteId } = useParams<{ siteId: string }>();

  const {
    data: settingsData,
    isSuccess,
    isFetching,
    isError,
  } = useListSiteUsersQuery({ siteId: Number(siteId) });

  const siteUsersProcessed = settingsData
    ? (assignUUIDToItems(settingsData.site_users) as unknown as IUser[])
    : [];

  const { execute, updateError, updateStatus } = useUpdate(
    async (data: any) => {
      const api = getApi();

      // Separate users to be deleted vs updated
      const toDelete = stripUUID(
        data.users.filter((user: IUser) => user._destroy)
      );
      const toUpdate = stripUUID(
        data.users.filter((user: IUser) => !user._destroy)
      );

      // Confirm any potential deletions just to be safe
      if (toDelete.length) {
        const r = await confirm({
          target: document.querySelector("#save") as HTMLElement,
          message: `Are you sure? Deleting users cannot be undone.`,
          kind: "danger",
        });
        if (!r) {
          return;
        }

        toDelete.map(({ site_user_id }: any) =>
          api.delete(`/sites/${siteId}/site_users/${site_user_id}`)
        );
      }

      toUpdate.map((user: any) =>
        api.patch(`/sites/${siteId}/site_users/${user.site_user_id}`, {
          site_id: siteId,
          site_user_id: user.site_user_id,
          roles: user.roles,
        })
      );
    },
    {}
  );

  return (
    <CompoundBox
      header={<H2>User Settings</H2>}
      border="none"
      shadow="box"
      style={{ width: "auto" }}
    >
      {isError && <Notice kind="danger">Could not retrieve data</Notice>}
      {(isFetching || !siteUsersProcessed) && (
        <Flex padding="xl" justify="center">
          <Spinner />
        </Flex>
      )}
      {isSuccess && (
        <Form
          // @ts-ignore
          initialData={siteUsersProcessed}
          mutators={{
            ...arrayMutators,
          }}
          validate={validationFns}
          onSubmit={execute}
          render={({
            handleSubmit,
            hasValidationErrors,
            pristine,
            errors,
            form: {
              mutators: { push },
            },
          }) => (
            <form>
              <Box bg="none">
                <Grid>
                  {/* Update error */}
                  {updateError && (
                    <Notice kind="danger">Error updating settings</Notice>
                  )}
                  <div>
                    <Button
                      id="user-add-new"
                      kind="primary"
                      onClick={() => setModal(true)}
                    >
                      <Flex gutter="sm">
                        <Icon kind="plus" size=".6em" />
                        <span>Add New User</span>
                      </Flex>
                    </Button>
                  </div>
                  {errors?.users && (
                    <Notice kind="warning">{errors.users}</Notice>
                  )}
                  <FieldArray
                    name="users"
                    //@ts-ignore
                    initialValue={siteUsersProcessed}
                    render={({ fields }) => {
                      return (
                        <Grid gutter="sm">
                          {fields.value.map((name, index) => {
                            return (
                              <Field
                                name="users"
                                key={`${name}-${index}`}
                                render={() => {
                                  return (
                                    <Users
                                      key={`${name}`}
                                      index={index}
                                      fields={fields}
                                      value={
                                        fields.value.filter(
                                          ({ _destroy }) => !_destroy
                                        )[index]
                                      }
                                      onUpdate={fields.update}
                                    />
                                  );
                                }}
                              />
                            );
                          })}
                        </Grid>
                      );
                    }}
                  />
                </Grid>
                <SettingsFooter
                  status={updateStatus}
                  formStatus={pristine}
                  onSubmit={handleSubmit}
                  invalid={hasValidationErrors}
                />
              </Box>
              {modal && (
                <Modal
                  id="add-user-modal"
                  labelledBy="add-user-label"
                  onClose={() => setModal(false)}
                  height="auto"
                >
                  <AddUserModal
                    siteId={siteId.toString()}
                    onAdd={(user) => push("users", { ...user, newUser: true })}
                    onClose={() => setModal(false)}
                  />
                </Modal>
              )}
            </form>
          )}
        />
      )}
    </CompoundBox>
  );
}
