import React, { SetStateAction, Dispatch } from "react";
import { Role } from "views/Settings/Users";
import { useUpdate } from "helpers/hooks";
import { JWTContextProps } from "@mediavine/ui/dist/Network/types";
import { getApi } from "./data";
import { Grid, Button, Text, alert, Network } from "@mediavine/ui";
import cache from "./cache";
import { HealthCheckResponse } from "helpers/types";

// eslint-disable-next-line
const MD5 = require("md5.js");

export type SiteUser = {
  site_id: number;
  roles: Role[];
};

export type UserData = {
  id: number;
  email: string;
  name: string;
  roles: Role[];
  site_ids: number[];
  favorite_sites: string;
  site_users: SiteUser[];
  super_admin: boolean;
  user_hash: string;
  gravatar?: string;
  twilio_verify_enabled?: boolean | null;
  premiere_sites: number[];
  premiere_sites_invited: number[];
  pro_sites: number[];
  pro_sites_invited: number[];
  enterprise_sites: number[];
};

export interface FavParams {
  startDate?: string;
  endDate?: string;
  sites?: number[];
}

export interface FavSitesData {
  favoritesIdList: number[];
  setFavoritesIdList: (p: number[]) => void;
}

interface FavProviderProps {
  userData: UserData;
  children: React.ReactNode;
}

interface UpdateData {
  id: number;
  favorite_sites: string;
}

export interface AdminBarContextProps {
  adminViewToggle: boolean;
  setAdminViewToggled: (v: boolean) => void;
  children: React.ReactNode;
}

interface AdminBarProviderProps {
  children: React.ReactNode;
}

interface AdminContextValue {
  adminViewToggled: boolean;
  setAdminViewToggled: (v: boolean) => void;
  isAdminBarOpen: boolean;
  setIsAdminBarOpen: (v: boolean) => void;
  simulatedUserData: UserData | null;
  setSimulatedUserData: Dispatch<SetStateAction<UserData | null>>;
}

export const AdminBarContext = React.createContext<AdminContextValue>({
  adminViewToggled: false,
  setAdminViewToggled: (v: boolean) => {},
  isAdminBarOpen: true,
  setIsAdminBarOpen: (v: boolean) => {},
  simulatedUserData: null,
  setSimulatedUserData: () => {},
});

export const AdminBarProvider = (props: AdminBarProviderProps) => {
  const [simulatedUserData, setSimulatedUserData] =
    React.useState<UserData | null>(cache.get("userDataAdminBar"));
  const [adminViewToggled, setAdminViewToggled] =
    React.useState<boolean>(false);
  const [isAdminBarOpen, setIsAdminBarOpen] = React.useState<boolean>(true);

  return (
    <AdminBarContext.Provider
      value={{
        adminViewToggled,
        setAdminViewToggled,
        isAdminBarOpen,
        setIsAdminBarOpen,
        simulatedUserData,
        setSimulatedUserData,
      }}
    >
      {props.children}
    </AdminBarContext.Provider>
  );
};

export const UserFavoritesContext = React.createContext<FavSitesData>({
  favoritesIdList: [],
  setFavoritesIdList: () => {},
});

export const UserFavoritesProvider = (props: FavProviderProps) => {
  const {
    userData: { id, favorite_sites },
  } = props;

  const { execute } = useUpdate(async (data: UpdateData) => {
    const api = getApi();
    const { id, favorite_sites } = data;
    const res = api.patch(`/users/${id}`, { favorite_sites });
    return res;
  }, {});

  const [favoritesIdList, setFavoritesIdList] = React.useState<number[]>(
    favorite_sites ? favorite_sites.split(",").map(Number).filter(Boolean) : []
  );

  const setFavoritesIdListWrapper = React.useCallback(
    (nextIdList: number[]) => {
      execute({ id, favorite_sites: nextIdList.join(",") });
      setFavoritesIdList(nextIdList);
    },
    [execute, setFavoritesIdList, id]
  );

  React.useEffect(() => {}, [favoritesIdList, execute, id]);

  return (
    <UserFavoritesContext.Provider
      value={{
        favoritesIdList,
        setFavoritesIdList: setFavoritesIdListWrapper,
      }}
    >
      {props.children}
    </UserFavoritesContext.Provider>
  );
};

export type UserContextProps = JWTContextProps<UserData>;

export type { SiteData } from "../state/context/site";

export const NavigationContext = React.createContext({ openDrawer: true });

export const DEFAULT_HEALTH_CHECKS_DATA: HealthCheckResponse = {
  meta: {
    report_start: "",
    report_end: "",
  },
  summary: {
    desktop_ads: 0,
    mobile_ads: 0,
    sticky_sidebar_ads: 0,
    desktop_viewability: 0,
    mobile_viewability: 0,
  },
  health_checks: [
    {
      ads_txt: null,
      date: "",
      desktop_ads: 0,
      mobile_ads: 0,
      privacy_policy: null,
      sticky_sidebar_ads: 0,
      desktop_viewability: 0,
      mobile_viewability: 0,
    },
    {
      ads_txt: null,
      date: "",
      desktop_ads: 0,
      mobile_ads: 0,
      privacy_policy: null,
      sticky_sidebar_ads: 0,
      desktop_viewability: 0,
      mobile_viewability: 0,
    },
    {
      ads_txt: null,
      date: "",
      desktop_ads: 0,
      mobile_ads: 0,
      privacy_policy: null,
      sticky_sidebar_ads: 0,
      desktop_viewability: 0,
      mobile_viewability: 0,
    },
  ],
};

export const HealthChecksDataContext = React.createContext(
  DEFAULT_HEALTH_CHECKS_DATA
);

export const withGravatar = (data: UserData): UserData => ({
  ...data,
  gravatar: `https://www.gravatar.com/avatar/${new MD5()
    .update(data.email)
    .digest("hex")}?d=mp`,
});

export const getUserData = async (userId: number, token: string) => {
  const api = getApi();
  const headers = { Authorization: `Bearer ${token}` };
  const { data } = await api.get(`/users/${userId}`, {
    headers,
  });
  return withGravatar(data.data.user);
};

/**
 * Alerts a user they're being logged out
 * if a 401 status code is received.
 */
export async function handle401() {
  const location = window.location;
  await alert({
    target: document.querySelector("#invalid-token") as HTMLElement,
    message: (
      <Grid>
        <Text>
          To give you the most up to date information, you will be redirected to
          the login screen to sign in again.
        </Text>
        <Button
          onClick={() => {
            cache.set({ jwt: undefined, twoFactorOk: undefined });
            location.replace(location.origin);
          }}
        >
          Ok
        </Button>
      </Grid>
    ) as React.ReactNode,
  });
}

export function useUserData() {
  const {
    userData,
    // @ts-ignore
  } = React.useContext<UserContextProps>(Network.JWTContext);

  return userData;
}
