import React from "react";
import { Box, Typography } from "@mui/material";
import { statusFlags } from "helpers/constants";
import CancelButton from "views/Settings/components/cancelButton";
import SaveButton from "views/Settings/components/saveButton";

const ConfirmDialogueActions: React.FC<IConfirmDialogueActions> = ({
  cancelHandler,
  saveHandler,
  saveTxt,
  cancelTxt,
}) => (
  <Box
    sx={{
      background: "transparent",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      padding: "0 16px",
      marginBottom: "16px",
    }}
  >
    <CancelButton clickHandler={cancelHandler}>
      <Typography>{cancelTxt}</Typography>
    </CancelButton>
    <SaveButton status={statusFlags.INITIAL} onClick={saveHandler}>
      <Typography>{saveTxt}</Typography>
    </SaveButton>
  </Box>
);

export default ConfirmDialogueActions;

interface IConfirmDialogueActions {
  cancelHandler: () => void;
  saveHandler: () => void;
  saveTxt: string;
  cancelTxt: string;
}
