import React, { useContext } from "react";
import { Network } from "@mediavine/ui";
import { Role } from "views/Settings/Users";
import { UserData, SiteUser } from "helpers/context";
import { SiteDataContext } from "state/context/site/siteCtx";
import { AdminBarContext } from "helpers/context";
import useIsAdmin from "helpers/hooks/useIsAdmin";

interface RequiresRoleProps {
  userRole: Role | Role[];
  children: React.ReactNode;
  fallback?: any;
}

export default function RequiresRole({
  userRole,
  fallback = null,
  children,
}: RequiresRoleProps) {
  const { id } = useContext(SiteDataContext);
  const {
    userData,
    // @ts-ignore
  } = React.useContext(Network.JWTContext) as { userData: UserData };
  const [hasPermission] = useHasRole(userData, userRole, id);
  return hasPermission ? children : fallback;
}

export function useHasRole(
  userData?: UserData,
  role?: Role | Role[],
  siteId?: number
): [
  boolean,
  (userData: UserData, role: Role | Role[], siteId: number) => boolean,
] {
  const { simulatedUserData, isAdminBarOpen } = useContext(AdminBarContext);
  // If user is admin and admin bar is open we set the flag
  // We check if user is admin becasue the admin bar provider has this var set as true by default
  const isAdminBarActive = useIsAdmin() && isAdminBarOpen;

  function hasUserRole(
    userData: UserData,
    role: Role | Role[],
    siteId: number
  ) {
    if (isAdminBarActive && simulatedUserData) {
      // If admin bar is active and being used we check against the admin bar faux data
      return (
        // Some components use RequiresRole with the admin role.
        // If we are using the admin bar we want to make sure to not
        // to allow rendering of an admin only component to an owner user
        (simulatedUserData?.roles.includes("owner") && role !== "admin") ||
        (!Array.isArray(role) && simulatedUserData?.roles.includes(role)) ||
        (Array.isArray(role) &&
          simulatedUserData?.roles.some((cRole) => role.includes(cRole))) ||
        false
      );
    }

    // Roles can only contain the admin role or be empty
    // Site_users is an array of sites with roles information (non admin related)
    const { roles: originalRoles } = userData;
    const { site_users: originalSiteUsers } = userData;

    let roles: Role[] = originalRoles;
    let site_users: SiteUser[] = originalSiteUsers;

    if (process.env.NODE_ENV === "development") {
      // If we have the react app env var defined, fetch the contents and replace the original roles
      if (process.env.REACT_APP_USER_ROLE) {
        roles = JSON.parse(process.env.REACT_APP_USER_ROLE);
      }
      // If we have the react app site user role env var defined, fetch the contents and replace the original site_users roles
      if (process.env.REACT_APP_SITE_USER_ROLE) {
        site_users = [
          {
            roles: JSON.parse(process.env.REACT_APP_SITE_USER_ROLE),
            site_id: Number(siteId),
          },
        ];
      }
    }

    // Search site_users for an entry matching the site id
    const userSiteFound = site_users.find(
      ({ site_id }) => site_id === Number(siteId)
    );

    return (
      roles.includes("admin") ||
      // Some components use RequiresRole with the admin role.
      // If we are using the admin bar we want to make sure to not
      // to allow rendering of an admin only component to an owner user
      (userSiteFound?.roles.includes("owner") && role !== "admin") ||
      (!Array.isArray(role) && userSiteFound?.roles.includes(role)) ||
      (Array.isArray(role) &&
        userSiteFound?.roles.some((rRole) => role.includes(rRole))) ||
      false
    );
  }

  let hasPermission = false;
  if (userData && role && siteId) {
    hasPermission = hasUserRole(userData, role, siteId);
  }

  return [hasPermission, hasUserRole];
}
