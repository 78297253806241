import React, { useContext, useEffect } from "react";
import { favoriteSites } from "helpers/cache";
import { UserFavoritesContext, FavSitesData } from "helpers/context";
import { Button, Icon, ScreenReader } from "@mediavine/ui";

export default function FavoriteSite({ id }: { id: number }) {
  const { favoritesIdList, setFavoritesIdList } =
    useContext<FavSitesData>(UserFavoritesContext);
  const favSiteFound = favoritesIdList?.some((favId: number) => favId === id);
  const [favorited, setFavorited] = React.useState<boolean>(
    favSiteFound || false
  );

  // Since this component is used in both the main and switch list we need
  // to react to changes made to the favoritesIdList in order to update the icon color
  useEffect(() => {
    const favSiteFound = favoritesIdList?.some((favId: number) => favId === id);
    setFavorited(favSiteFound || false);
  }, [favoritesIdList, setFavorited, id]);

  const handleClick = async () => {
    setFavorited((v) => !v);
    if (!favorited) {
      setFavoritesIdList([...favoritesIdList, id]);
    } else {
      setFavoritesIdList(favoritesIdList?.filter((siteId) => siteId !== id));
      // we need this since users might want to get rid of sites which
      // might be both in cache and backend. This is due to the requirement to
      // load users from cache if there are any
      favoriteSites.purge(id);
    }
  };

  return (
    <Button kind="icon" onClick={handleClick}>
      <ScreenReader>Add sites to favorite</ScreenReader>
      <Icon
        kind="star"
        size="sm"
        color={favorited ? "warning" : "lightgrey"}
        hoverColor="teal"
      />
    </Button>
  );
}
